import { ISolitaire } from "./solitaire";
import { RunLog } from "./runLog";
import { FailureHistory } from "./failureHistory";
import { ResultBody } from "./resultBody";
import { SolitaireLayoutOverview } from "./solitaireLayoutOverview";
import { ISolitaireLayoutOutput, SolitaireLayoutInternal } from "./baseSolitaireLayout";
import { ISolutionSet } from "./solutionSet";
import { ISolitaireLayout } from "./solitaireLayout";
import { Failures } from "./failures";
import { Outcome } from "./outcome";
import { SimulationsFor, SolitaireState } from "./solitaireState";

export abstract class GenericPlayResult {
    initialTableau: object | undefined;
    maximalDistanceFromInitialTableau!: number;
    startTime: Date | undefined;
    endTime: Date | undefined;
    milliseconds!: number;
    steps!: number;
    amountOfFailures: number | undefined;
    hitsAndQuits: number | undefined;
    version: string | undefined;
    hitMaxHistoryLength!: boolean;
}

export abstract class PlayResult<TSolitaire extends ISolitaire> extends GenericPlayResult {
    outcome: number | undefined;
    statistics: SolitaireState<TSolitaire> = new SolitaireState<TSolitaire>();
    hasLog<TRunLog extends RunLog>() : this is PlayResultWithLog<TSolitaire, TRunLog> {
        return 'log' in this;
    }
    hasVerificationResult<TRunLog extends RunLog>() : this is VerificationResult<TSolitaire, TRunLog> {
        return 'verificationResult' in this;
    }
    hasHistoryIfWon<TLayout extends ISolitaireLayoutOutput<TSolitaire>>() : this is PlayResultWithWinningHistory<TSolitaire, TLayout> {
        return 'history' in this;
    }
}

export abstract class PlayResultWithLog<TSolitaire extends ISolitaire, TRunLog extends RunLog> extends PlayResult<TSolitaire> {
    log: TRunLog | undefined;
}

export abstract class PlayResultWithWinningHistory<TSolitaire extends ISolitaire,
TLayout extends ISolitaireLayoutOutput<TSolitaire>> extends PlayResult<TSolitaire> {
    simulationsOutput: SimulationsFor<TSolitaire, TLayout> | null | undefined;
}

export abstract class VerificationResult<TSolitaire extends ISolitaire, TRunLog extends RunLog> extends PlayResultWithLog<TSolitaire, TRunLog> {
    verificationResult!: boolean;
}

export class SimulationResult<TSolitaire extends ISolitaire,
TLayoutOverview extends SolitaireLayoutOverview<TSolitaire, TLayout, TSolutionSet>,    
TLayout extends SolitaireLayoutInternal<TSolitaire, TSolutionSet> & ISolitaireLayout<TSolitaire>,
TSolutionSet extends ISolutionSet<TSolitaire>,
TResultBody extends ResultBody<TSolitaire>> extends GenericPlayResult {
    outcome!: Outcome;
    history!: TLayoutOverview[];
    isGamePossibleToWinFromOutset!: TResultBody;
    layoutsThatWillLeadToFailure!: Failures;
    failureHistory!: FailureHistory;

    public constructor(init?: Partial<SimulationResult<TSolitaire, TLayoutOverview, TLayout, TSolutionSet, TResultBody>>) {
        super();
        Object.assign(this, init);
    }
}