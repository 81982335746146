import { Card } from "../../card";
import { Helper } from "../../helper";
import { IBlockMethods } from "../blockMethods";
import { ScorpionBlock, ScorpionBlockLog } from "./scorpionBlock";
import { ScorpionLayout } from "./scorpionLayout";
import { ScorpionResultBody } from "./scorpionResultBody";
import { ScorpionSolitaire } from "./scorpionSolitaire";

export class ScorpionBlockMethods implements IBlockMethods<ScorpionSolitaire, ScorpionLayout, ScorpionResultBody> {
    
    isGamePossibleToWinFromOutset(layout: ScorpionLayout, withLogging: boolean) : ScorpionResultBody {
        // See http://solitaireinnovations.com/filtered_games.html

        let faceDownCards = layout.faceDownCards;
        let columns = layout.columns;
        let clth = columns.length;

        let savedBodyWithBlock;
        for (let i = 0; i < clth; i++) {
            let f = columns[i];
            for (let j = 0; j < f.length - 1; j++) {
                let body = new ScorpionBlock([i], [j], [], []);

                let newBody = this.findPossibleTopCardForBlock(body, columns, faceDownCards);
                //if (newBody.upsideDown === null) { continue; }
                if (newBody.foundBlock) {
                    // Construct face-down sequence
                    let rs = [];
                    for (let m = 0; m < newBody.column.length; m++) rs.push(newBody.top[m] - (1 + newBody.upsideDown[m] + newBody.bottom[m]));
                    newBody.faceDown = rs;
                    if (!newBody.foundProperBlock) {
                        savedBodyWithBlock = newBody;
                        continue;
                    }
                    if (withLogging) {
                        let cs = newBody.column, is = newBody.bottom, ss = newBody.upsideDown, ts = newBody.top;
                        if (cs.length === 1) {
                            if (cs[0] < faceDownCards.length && is[0] < faceDownCards[cs[0]].length) {
                                if (ss[0] === 0) { Helper.log("Face-down block encountered in column " + cs[0] + " (cards " + columns[cs[0]][is[0]] + ", " + columns[cs[0]][ts[0]] + ")"); }
                                else { Helper.log("Face-down/upside-down block encountered in column " + cs[0] + " (cards " + columns[cs[0]][is[0]] + ", " + columns[cs[0]][ts[0]] + ")"); }
                            }
                            else { Helper.log("Upside-down block encountered in column " + cs[0] + " from positions " + is[0] + " to " + ts[0]); }
                        }
                        else if (ss.length === 2 && ts[0] - is[0] + ts[1] - is[1] === 2 && ss[0] === 0 && ss[1] === 0) {
                            let r = cs[0], s = cs[1];
                            Helper.log("Criss-cross encountered in columns " + r + " (cards " + columns[r][is[0]] + ", " + columns[r][ts[0]] + ") and " + s + " (cards " + columns[s][is[1]] + ", " + columns[s][ts[1]] + ")")
                        }
                        else {
                            let isLarge = 0;
                            for (let m = 0; m < cs.length; m++) isLarge += rs[m];
                            if (isLarge === 0) {
                                Helper.log("Found crown block of length " + cs.length + " with column sequence " + JSON.stringify(cs) + ", index sequence " + JSON.stringify(is) + " and upside-down sequence " + JSON.stringify(ss));
                            } else {
                                let allBottomCardsAreFacedown = true;
                                for (let m = 0; m < cs.length; m++) {
                                    allBottomCardsAreFacedown = cs[m] < faceDownCards.length && is[m] < faceDownCards[cs[m]].length;
                                    if (!allBottomCardsAreFacedown) break;
                                }
                                if (allBottomCardsAreFacedown) {
                                    Helper.log("Expanded face-down block of length " + cs.length + " encountered, with column sequence " + JSON.stringify(cs) + ", bottom sequence " + JSON.stringify(is) + " and top sequence " + JSON.stringify(ts));
                                } else {
                                    let isTrueLargeBlock = true;
                                    for (let i = 0; i < cs.length; i++) {
                                        for (let j = i + 1; j < cs.length; j++) {
                                            if (cs[j] === cs[i] && is[j] === is[i]) isTrueLargeBlock = false;
                                        }
                                    }
                                    if (isTrueLargeBlock) {
                                        Helper.log("Found large block of length " + cs.length + " with column sequence " + JSON.stringify(cs) + ", index sequence " + JSON.stringify(is) + ", face-down sequence " + JSON.stringify(rs) + " and upside-down sequence " + JSON.stringify(ss));
                                    }
                                }
                            }
                        }
                    }
                    return this.convertToResultBody(newBody, false);
                }
            }
        }

        if (savedBodyWithBlock !== undefined) {
            debugger;
            if (withLogging) {
                let cs = savedBodyWithBlock.column, is = savedBodyWithBlock.bottom, ss = savedBodyWithBlock.space, ts = savedBodyWithBlock.top;
                let rs = [];
                for (let m = 0; m < cs.length; m++) rs.push(ts[m] - (1 + (ss[m] || 0) + is[m]));
                Helper.log("Found pseudo-large block of length " + cs.length + " with column sequence " + JSON.stringify(cs) + ", index sequence " + JSON.stringify(is) + ", face-down sequence " + JSON.stringify(rs) + " and space sequence " + JSON.stringify(ss));
            }
            return this.convertToResultBody(savedBodyWithBlock, false);
        }

        return this.convertToResultBody(null, true);
    }    

    // Face-down block: For instance, if the first, second or third card of the first column (face-down) is ten of hearts and the fourth (face up) is nine of hearts, the game cannot be won (nine of hearts cannot be moved anywhere). It may include more columns (see also the criss-cross block below)

    // Upside-down block: When a group of cards of the same color are laid out in a column in a way such that the largest card in the bottom and the rest are the successors in reverse order (for instance, He9, He6, He7, He8). Then He8 can never be placed on He9.

    // Criss-cross block: When two cards are covered by each others card one rank below in suit sequence (for instance, in one column have Sp8, Sp4, in another have Sp5, Sp7. Now Sp7 can never be placed on Sp8

    // Crown blocks: We combine the notions of upside-down blocks with those of criss-cross blocks.
    findPossibleTopCardForBlock(b: ScorpionBlock, columns: number[][], faceDownCards: number[][]): ScorpionBlock {
        let body = JSON.parse(JSON.stringify(b)) as ScorpionBlock;
        // Any repeat pattern of length more than 2 comes from a (smaller) block - it (probably!) comes from a later column, so let us catch it there. (There is probably some neater way of doing this, but this is way more pedagogical.)
        let l = body.column.length - 1;
        for (let p = 0; p > 0; p--) {//for (let p = l - 1; p > 0; p--) {
            if (body.column[p] === body.column[l] && body.bottom[p] === body.bottom[l] && body.top[p] === body.top[l] && body.space[p] === body.space[l]) {
                return body;
            }
        }

        var latestColumn = body.column[l];
        var latestBottomIndex = body.bottom[l];

        var lastBottomCard = columns[latestColumn][latestBottomIndex];
        var topCardToSearchFor = lastBottomCard + 1;
        // The next top card cannot be a king, and if it is directly on top of the last bottom card, then that bottom card needs to be face-down
        // If we always check that the next bottom card has not yet been part of the sequence, part of this check should not be necessary
        if ((columns[latestColumn][latestBottomIndex + 1] === topCardToSearchFor && (latestColumn >= faceDownCards.length || faceDownCards[latestColumn].indexOf(lastBottomCard) === -1)) || Card.isKing(topCardToSearchFor)) {
            return body;
        }
        let a = 0, d; // a the column of the successor, d the index of the successor
        while (a < columns.length) {
            let index = columns[a].indexOf(topCardToSearchFor);
            if (index === -1) { a++; }
            else {
                if (index > 0) d = index;  // The top card must be on top of at least one other card
                break;
            }
        }
        if (d === undefined) {
            // We did not find this particular possible top card anywhere; abort mission
            return body;
        }
        // We look down through the column to find the upside-down sequence
        let upsideDown = 0, foundTheSpace = false;
        while (!foundTheSpace) {
            let e = d - 1 - upsideDown;
            foundTheSpace = e === 0 || Card.isKing(columns[a][e]) || columns[a][e] !== columns[a][e + 1] + 1;
            if (!foundTheSpace) upsideDown++;
        }
        // The value of the space variable is m_t
        // The last criterion deserves an explanation: if the first bottom card is face-down and below the current top card (if that top card is, say, directly upon the same face-down pile if space = 0), then we are done
        if (body.column[0] === a && body.bottom.length > 0 && (body.bottom[0] === d - 1 - upsideDown || (a < faceDownCards.length && d - 1 - upsideDown < faceDownCards[a].length && body.bottom[0] < d - upsideDown))) {
            // We have wound up back at the beginning so we are done! Return to home base
            body.top[0] = d;
            body.upsideDown[0] = upsideDown;
            body.foundBlock = true;
            let isProperLargeBlock = true;
            for (let i = 0; i < body.column.length; i++) {
                for (let j = i + 1; j < body.column.length; j++) {
                    if (body.column[j] === body.column[i] && body.bottom[j] === body.bottom[i]) isProperLargeBlock = false;
                }
            }
            body.foundProperBlock = isProperLargeBlock;
            return body;
        }

        for (let x = body.column.length - 1; x > -1; x--) {
            if (body.column[x] === a && body.bottom.length > 0 && (body.bottom[x] === d - 1 - upsideDown || (a < faceDownCards.length && d - 1 - upsideDown < faceDownCards[a].length && body.bottom[x] < d - upsideDown))) return body;
        }

        // The search goes on; now take the card below the last found "space" card and prepare to initiate the same process with this new body
        let newBody = JSON.parse(JSON.stringify(body)) as ScorpionBlock;
        newBody.column.push(a);
        newBody.top[newBody.column.length - 1] = d;
        newBody.upsideDown[newBody.column.length - 1] = upsideDown;
        // As d - 1 - space = k_t + r_t, we next find the lower limit of what k_t may be - note that k_t > |d_{i_t}| in which case k_t + r_t > |d_{i_t}|, or k_t <= |d_{i_t}| in which case k_t + r_t <= |d_{i_t}|:
        let nextBottomIndexLowerLimit = a < faceDownCards.length && d - 1 - upsideDown < faceDownCards[a].length ? 0 : d - 1 - upsideDown;

        for (let m = d - 1 - upsideDown; m >= nextBottomIndexLowerLimit; m--) {
            newBody.bottom.push(m);
            newBody = this.findPossibleTopCardForBlock(newBody, columns, faceDownCards);
            if (newBody.foundBlock) { return newBody; }
            else { newBody.bottom.pop(); }
        }

        // debugger;
        return body;
    }
    
    convertToResultBody(body: ScorpionBlock | null, result: boolean): ScorpionResultBody {
        if (body == null) return new ScorpionResultBody(null, result);
        let forLog = new ScorpionBlockLog(body);
        if (Object.keys(body).length > 0 && body.faceDown !== null && body.upsideDown !== null) {
            let replaceUpDownWithNull = true, replaceFaceDownWithNull = true;
            for (let i = 0; i < body.upsideDown.length; i++) {
                var m = body.upsideDown[i];
                if (m !== undefined && m > 0) { replaceUpDownWithNull = false; break; }
            }
            for (let i = 0; i < body.faceDown.length; i++) {
                var n = body.faceDown[i];
                if (n !== undefined && n > 0) { replaceFaceDownWithNull = false; break; }
            }
            if (replaceUpDownWithNull) forLog.upsideDown = null;
            if (replaceFaceDownWithNull) forLog.faceDown = null;
        }
        return new ScorpionResultBody(forLog, result);
    }
}