import React from "react";
import { Container, Col, Row } from 'react-bootstrap';
import { ReactNode } from "react";
import { ISolitaire } from "../Models/solitaire";
import { ISolitaireStateWithStatistics } from "../Models/solitaireState";
import { ISolitaireViewProps } from "../Models/solitaireViewProps";

export interface IStatisticsViewProps<TSolitaire extends ISolitaire,
TSolitaireState extends ISolitaireStateWithStatistics<TSolitaire>> extends ISolitaireViewProps<TSolitaire, TSolitaireState> {}

export class StatisticsView<TSolitaire extends ISolitaire,
TSolitaireState extends ISolitaireStateWithStatistics<TSolitaire>> extends React.Component<IStatisticsViewProps<TSolitaire, TSolitaireState>> {

    render() : ReactNode {
        const {solitaireState} = this.props;
        return <Container>
        <Row>
            <Col sm>
                <span>Empirical probability:</span>
                <h2>{solitaireState.statistics.percentageString}</h2>
            </Col>
        </Row>
        <Row>
            <Col sm>
                <span>Games won:</span>
                <h2>{solitaireState.statistics.won.toLocaleString()}</h2>
            </Col>
            <Col sm>
                <span>Games lost:</span>
                <h2>{solitaireState.statistics.lost.toLocaleString()}</h2>
            </Col>
            <Col sm>
                <span>Unwinnable games:</span>
                <h2>{solitaireState.statistics.unwinnable.toLocaleString()}</h2>
            </Col>
            <Col sm>
                <span>Undetermined outcomes:</span>
                <h2>{solitaireState.statistics.undetermined.toLocaleString()}</h2>
            </Col>
        </Row>
        <Row>
            <Col sm>
                <span>Total number of games played:</span>
                <h2>{solitaireState.total.toLocaleString()}</h2>
            </Col>
            <Col sm>
                <span>Top number of moves required to determine outcome:</span>
                <h2>{solitaireState.topNumberOfStepsRequired !== undefined ? solitaireState.topNumberOfStepsRequired.toLocaleString() : ""}</h2>
            </Col>
        </Row>
    </Container>
    }
}