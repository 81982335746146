import { ISolitaire } from '../Models/solitaire'
import { ResultBody } from './resultBody'
import { ISolitaireLayout } from './solitaireLayout'
import { ISolutionSet } from './solutionSet'
import { MoveConsequence } from './moveConsequence'
import { SolitaireLayoutOverview } from './solitaireLayoutOverview'
import { SolitaireLayoutInternal } from './baseSolitaireLayout'
import { MoveObject } from './moveObject'
import { SolitaireMove } from './solitaireMove'
import { Failures } from './failures'
import { HistoryMethods } from './historyMethods'
import { FailureHistory } from './failureHistory'
import { RunLog } from './runLog'

export abstract class MoveMethods<TSolitaire extends ISolitaire,
    TMoveConsequence extends MoveConsequence<TSolitaire, TLayout>,
    TLayout extends SolitaireLayoutInternal<TSolitaire, TSolutionSet> & ISolitaireLayout<TSolitaire>,
    TSolutionSet extends ISolutionSet<TSolitaire>, 
    TResultBody extends ResultBody<TSolitaire>,
    TRunLog extends RunLog>
{
    historyMethods : HistoryMethods<TSolitaire, TLayout, TSolutionSet> = new HistoryMethods<TSolitaire, TLayout, TSolutionSet>();    

    addToFailureHistory<TLayoutOverview extends SolitaireLayoutOverview<TSolitaire, TLayout, TSolutionSet>>(failureHistory: FailureHistory, history: TLayoutOverview[], steps: number) {
        return this.historyMethods.addToFailureHistory(failureHistory, history, steps);
    }
    
    abstract getResultBody(resultBody: TResultBody, failureHistory: FailureHistory) : TRunLog;

    decideWhichMoveToDo(step: SolitaireLayoutOverview<TSolitaire, TLayout, TSolutionSet>) : MoveObject<TSolitaire> 
    {
        var stepsUntried : number[] = []; // Find the moves not yet made
        for (var i = 0; i < step.possibleMoves.length; i++)
        {
            if (step.movesTriedOut.indexOf(i) === -1) stepsUntried.push(i);
        };

        // First attempt to return a non-trivial move
        for (var j = 0; j < stepsUntried.length; j++)
        {
            var index = stepsUntried[j];
            var move = step.possibleMoves[index];
            if (!move.isTrivial()) return new MoveObject<TSolitaire>(index, move);
        }

        // Otherwise, use first available move
        return new MoveObject<TSolitaire>(stepsUntried[0], step.possibleMoves[stepsUntried[0]]);
    }

    willNextMoveMakeGameImpossibleToWin(newMove: TMoveConsequence) : boolean
    {
        return false;
    }

    abstract performMove(layout: TLayout, theMoveToDo: SolitaireMove<TSolitaire>) : TMoveConsequence;

    moveCards<T extends SolitaireLayoutOverview<TSolitaire, TLayout, TSolutionSet>>
    (solitaire: TSolitaire, history: T[], layoutsThatWillLeadToFailure: Failures) : T[]
{
    let newHistory = history.slice(0);
    let latestStep = newHistory[newHistory.length - 1];
    let solutionString = latestStep.columnsAndLeftovers.solutionSet.translateToString();

    if (layoutsThatWillLeadToFailure.isContainedInFailures(solutionString)) {
        for (let i = 0; i < latestStep.possibleMoves.length; i++) {
            latestStep.movesTriedOut.push(i);
        }
    }
    else if (latestStep.possibleMoves.length > 0 && latestStep.movesTriedOut.length === 0 && latestStep.possibleMoves.filter(x => !x.isTrivial()).length === 0) {
        // If there are only trivial moves, just ignore them
        if (!layoutsThatWillLeadToFailure.isContainedInFailures(solutionString)) layoutsThatWillLeadToFailure.addLayoutToFailures(solutionString);
        for (let i = 0; i < latestStep.possibleMoves.length; i++) {
            latestStep.movesTriedOut.push(i);
        }
    }
    if (latestStep.possibleMoves.length > latestStep.movesTriedOut.length) {
        let theMoveToDo = this.decideWhichMoveToDo(latestStep);
        let nextMove = theMoveToDo.theNextMove;
        let newMove = this.performMove(latestStep.columnsAndLeftovers, nextMove);
        let newColumnsAndLeftovers = newMove.step;
        let nextMoveIsTrivial = nextMove.isTrivial();

        latestStep.movesTriedOut.push(theMoveToDo.index);
        let newSolutionString = newColumnsAndLeftovers.solutionSet.translateToString();
        if (nextMoveIsTrivial && this.historyMethods.hasBeenSeenBeforeAfterSequenceOfTrivialMoves(newHistory, newSolutionString)) return newHistory;
        // Do not perform moves to layouts with exactly the same solution string
        // Moreover, if the next layout has the same solution string as one of the previous layouts (and this next layout is obtained after only performing trivial moves on the previous layout), then we can just proceed, as we only allow layout histories with distinct solution strings
        if (solitaire.checkAllPreviousSolutionStrings && this.historyMethods.hasBeenSeenBefore(newHistory, newSolutionString))
        {
            return newHistory;
        }

        if (this.willNextMoveMakeGameImpossibleToWin(newMove))
        {
            layoutsThatWillLeadToFailure.addLayoutToFailures(newSolutionString);
        } else {
            if (!layoutsThatWillLeadToFailure.isContainedInFailures(newSolutionString)) {
                // var overview = new type();
                var overview = {
                    columnsAndLeftovers: newColumnsAndLeftovers,
                    possibleMoves: this.investigatePossibleMoves(newColumnsAndLeftovers),
                    trivialMovesPerformed: latestStep.trivialMovesPerformed + (nextMoveIsTrivial ? 1 : 0),
                    movesTriedOut: [] as number[]
                } as T;
                // overview.columnsAndLeftovers = newColumnsAndLeftovers;
                // overview.possibleMoves = newColumnsAndLeftovers.investigatePossibleMoves();
                // overview.trivialMovesPerformed = latestStep.trivialMovesPerformed + (nextMoveIsTrivial ? 1 : 0);

                newHistory.push(overview);
            } else {
                layoutsThatWillLeadToFailure.hitItAndQuitIt++;
            }
        }
    
    } else {
        if (newHistory.length > 1) {
            newHistory.pop();
            if (!layoutsThatWillLeadToFailure.isContainedInFailures(solutionString)) layoutsThatWillLeadToFailure.addLayoutToFailures(solutionString);

        } else {
            latestStep.exhaustedAllPossibleMoves = true;
        }
    }
    return newHistory;
    }

    abstract investigatePossibleMoves(layout: TLayout) : SolitaireMove<TSolitaire>[];
}

    