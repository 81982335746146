import { FailureHistory } from './failureHistory';

export class RunLog
{
    history!: FailureHistory;
    // constructor(history: FailureHistory) {
    //     this.history = history;
    // }
    constructor(init?: Partial<RunLog>) {
        return Object.assign(this, init);
    }
}