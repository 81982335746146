import React from "react";
import { ReactNode } from "react";
import { Button, Card, Row, Col, Form } from 'react-bootstrap';
import { Card as Cards } from "../card";
import { Helper } from "../helper";
import { ISolitaireLayoutOutput } from "../Models/baseSolitaireLayout";
import { ISolitaire } from "../Models/solitaire";
import { ISolitaireStateWithSimulations, Simulations } from "../Models/solitaireState";
import { SolitaireTypeOf } from "../Models/solitaireType";
import { ISolitaireViewProps } from "../Models/solitaireViewProps";

export interface ISimulationViewProps<TSolitaire extends ISolitaire,
TSolitaireState extends ISolitaireStateWithSimulations<TSolitaire, TLayout>, TLayout extends ISolitaireLayoutOutput<TSolitaire>>
extends ISolitaireViewProps<TSolitaire, TSolitaireState> {
    steps: number;
    enableFunctionality: boolean;
    runWrapper: (runMethod: () => void) => void;
    changeSimulation: (solitaireType: SolitaireTypeOf<TSolitaire>, solitaireState: TSolitaireState, simulations: Simulations) => void;
}

export class SimulationViewState {
    hasResult!: boolean;
}

export class SimulationView<TSolitaire extends ISolitaire,
TSolitaireState extends ISolitaireStateWithSimulations<TSolitaire, TLayout>, TLayout extends ISolitaireLayoutOutput<TSolitaire>> extends React.Component<ISimulationViewProps<TSolitaire, TSolitaireState, TLayout>, SimulationViewState> {
    constructor(props: ISimulationViewProps<TSolitaire, TSolitaireState, TLayout>) {
        super(props);
        this._onSimulationChange = this._onSimulationChange.bind(this);
        this._simulationIndexChange = this._simulationIndexChange.bind(this);
        this.simulateSolitaire = this.simulateSolitaire.bind(this);
        this.state = {
            hasResult: false
        }
    }
    
    _onSimulationChange(e: React.FormEvent<HTMLTextAreaElement>) {
        var simulations = new Simulations();
        simulations.simulationIndex = 0;
        simulations.simulationToRun = e.currentTarget.value;
        this.setState({hasResult:false});
        this.props.changeSimulation(this.props.solitaireType, this.props.solitaireState, simulations);
    }
    
    _simulationIndexChange(index: number) {
        const {solitaireState} = this.props;
        let history = solitaireState.simulations.historyResult;
        let oldIndex = solitaireState.simulations.simulationIndex;
        let newIndex = oldIndex + index;
        if (history === null || newIndex > history.length - 1 || newIndex < 0) return;
        solitaireState.simulations.simulationIndex = newIndex;
        this.props.changeSimulation(this.props.solitaireType, this.props.solitaireState, solitaireState.simulations);
    }
    
    simulate = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        if (this.props.enableFunctionality) {
            this.props.runWrapper(this.simulateSolitaire);
        } else {            
            alert('You do not have permission to perform this operation.');
        }
    }

    simulateSolitaire() {       
        let solitaire = new this.props.solitaireType();
        if (!solitaire.canBeSimulated()) return;

        let simulations = this.props.solitaireState.simulations;
        var simulationModel = solitaire.convertJsonToSimulationModel(simulations.simulationToRun);
        var result = solitaire.simulate(this.props.steps, this.props.solitaireState, simulationModel);
        this.setState({hasResult:true});
        if (result.simulationsOutput !== null && result.simulationsOutput !== undefined){
            result.simulationsOutput.simulationIndex = 0;
            result.simulationsOutput.simulationToRun = simulations.simulationToRun;
            this.props.changeSimulation(this.props.solitaireType, this.props.solitaireState, result.simulationsOutput);
        }
    }

    render() : ReactNode {
        const {solitaireState} = this.props;
        let simulations = solitaireState.simulations;
        var isValidJson = simulations.simulationToRun === undefined || simulations.simulationToRun === '' || Helper.isJsonString(simulations.simulationToRun);
        let history = simulations.historyResult;
        const css = `
        span.playing-card img {width: 80px;}
        div.simulate-column {width: 80px; margin-right: 25px;display:inline-block;}
        span.simulate-column-card {position: absolute;}
        `

        return <Card className="text-center">
            <Card.Body>
                <style>{css}</style>
                <h4 className="card-title">Simulate</h4>
                <form onSubmit={this.simulate}>
                    <Row>
                        <Col sm className="text-right form-group">Solitaire to simulate:
                            {!isValidJson &&
                                <p style={{color: "red", fontWeight: "bold"}}>Invalid JSON</p>
                            }
                        </Col>
                        <Col sm className="text-left form-group">
                            <textarea
                                placeholder=""
                                className="form-control"
                                onChange={this._onSimulationChange}
                                value={simulations.simulationToRun}
                                rows={10}></textarea>
                        </Col>
                    </Row>                               
                <Row style={{ alignItems: 'center' }}>
                        <Col sm></Col>
                        <Col sm className="text-left form-group">
                            <input type="submit" className="btn btn-primary" value="Simulate" />
                        </Col>
                    </Row>    
                    </form>
                {solitaireState !== undefined && history !== null && history !== undefined && history.length > 0 ?
                    <div>
                        <Form.Group>
                            <div>
                            <Button
                                className={"btn btn-primary function-button mr-1"}
                                disabled={history === null || history === undefined || simulations.simulationIndex < 1}
                                onClick={evt => this._simulationIndexChange(-1)}>
                                Previous
                            </Button>
                            <Button
                                className={"btn btn-primary function-button mr-1"}
                                disabled={history === null || history === undefined || simulations.simulationIndex > history.length - 2}
                                onClick={evt => this._simulationIndexChange(1)}>
                                Next
                            </Button>
                            </div>
                        </Form.Group>
                        <div style={{position:'relative'}}>
                            {history[solitaireState.simulations.simulationIndex].columns.map((column, columnIndex) => {
                                return <div key={"c"+columnIndex} className="simulate-column" style={{height: column.length*25+80 + "px"}}>
                                    {column.map((card, cardIndex) => {
                                        var layout = history !== null && history !== undefined ? history[solitaireState!.simulations.simulationIndex] : null;
                                        return <span key={"card"+cardIndex} className="simulate-column-card playing-card" style={{zIndex: cardIndex, top: cardIndex*25 + "px"}}>
                                            {React.createElement("card-t",  {
                                                rank: columnIndex < (layout !== null ? layout.faceDownCards.length : 0)
                                                && cardIndex < (layout !== null ? layout.faceDownCards[columnIndex].length : 0) ? 0 : Cards.getRank(card), suit: Cards.getSuit(card)
                                            })}
                                        </span>
                                    })}
                                </div>
                            })}
                        </div>       
                    </div>
                : ""}
                {this.state.hasResult && (history === null || history === undefined || history.length === 0)
                ?
                'Solitaire cannot be won, and hence not simulated graphically'
                :
                ''}   
            </Card.Body>
        </Card>
    }
}