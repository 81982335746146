import { ISolutionSet } from '../solutionSet'
import { ScorpionSolitaire } from "./scorpionSolitaire";
import { Card } from '../../card'

export class ScorpionSolutionSet implements ISolutionSet<ScorpionSolitaire> {
    solved!: number[];
    leftovers!: { [key: number]: number; };
    winnabilityHelper!: { [key: number]: number | string; } // Only values are integers and the string "-"

    // constructor(solved: number[], leftovers: { [key: number]: number; }, winnabilityHelper: { [key: string]: number | string; }) {
    //     this.solved = solved;
    //     this.leftovers = leftovers;
    //     this.winnabilityHelper = winnabilityHelper;
    // }

    constructor(init?: Partial<ScorpionSolutionSet>) {
        return Object.assign(this, init);
    }

    translateToString() : string {
        let string = "";

        let solved = this.solved, size = 0;
        for (let j = 0; j < solved.length; j++) {
            let card = solved[j];
            if (size === 0) string += Card.convertCardToChar(card);
            if (j < solved.length - 1) {
                if (solved[j + 1] === card + 1 && !Card.isKing(card + 1)) { size++; }
                else {
                    if (size > 0) { string += String.fromCharCode(47 + size); }
                    size = 0;
                }
            } else {
                if (size > 0) { string += String.fromCharCode(47 + size); }
                size = 0;
            }
        }

        string += "|";
        // Only add winnability helper string if it is present
        // This part of the string is always sorted by the numerical order of the leftover columns (which are fixed for each game)
        let win = this.winnabilityHelper;
        if (Object.keys(win).length > 0) {
            for (let s = 0; s < Object.keys(win).length; s++) {
                let value = win[+Object.keys(win)[s]];
                string += value === "-" || value === 0 ? value : Card.convertCardToChar(+value);
            }
        }

        string += "|";
        // Only add this string if the left-over cards have been played
        // This part of the string is always sorted by the numerical order of the leftover cards themselves (which are also fixed for each game)
        let leftovers = this.leftovers;
        if (Object.keys(leftovers).length > 0) {
            for (let k = 0; k < Object.keys(leftovers).length; k++) {
                let value = leftovers[+Object.keys(leftovers)[k]];
                string += value < 0 ? "-" : (value === 0 ? "0" : Card.convertCardToChar(value));
            }
        }

        return string;
    }
}