// eslint-disable-next-line @typescript-eslint/no-namespace
export class Time {
    static convertFromMilliseconds(ms: number) {
        var cd = 24 * 60 * 60 * 1000,
            ch = 60 * 60 * 1000,
            cm = 60 * 1000,
            cs = 1000,
            d = Math.floor(ms / cd),
            h = Math.floor((ms - d * cd) / ch),
            m = Math.floor((ms - d * cd - h * ch) / cm),
            s = Math.floor((ms - d * cd - h * ch - m * cm) / cs),
            rest = ms - d * cd - h * ch - m * cm - s * cs,
            pad = function (n: number) { return n < 10 ? '0' + n : n; },
            pad2 = function (n: number) { return n < 100 ? (n < 10 ? "0" : "") + "0" + n : n };
        if (s === 60) { m++; s = 0; }
        if (m === 60) { h++; m = 0; }
        if (h === 24) { d++; h = 0; }
        return d + ":" + pad(h) + ":" + pad(m) + ":" + pad(s) + "." + pad2(rest);
    }
}