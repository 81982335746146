import { PlayResultWithLog, PlayResultWithWinningHistory } from "../playResult";
import { ScorpionLayout } from "./scorpionLayout";
import { ScorpionRunLog } from "./scorpionRunLog";
import { ScorpionSolitaire } from "./scorpionSolitaire";

export class ScorpionPlayResult extends PlayResultWithLog<ScorpionSolitaire, ScorpionRunLog> {
    wonWithTrivialKingMove: boolean | undefined;
}

export class ScorpionPlayResultWithWinningHistory extends PlayResultWithWinningHistory
    <ScorpionSolitaire, ScorpionLayout> {
    wonWithTrivialKingMove: boolean | undefined;
}