import { MoveConsequence } from "../moveConsequence";
import { ScorpionSolitaire } from "./scorpionSolitaire";
import { ScorpionLayout } from "./scorpionLayout";

export class ScorpionMoveConsequence extends MoveConsequence<ScorpionSolitaire, ScorpionLayout> {
    wasExternal: boolean;

    constructor(step: ScorpionLayout, wasExternal: boolean) {
        super(step);
        this.wasExternal = wasExternal;
    }
}