import { ISolitaire, Solitaire } from "../solitaire";
import { State } from "../state";
import { IdiotLayoutService } from "../../Services/idiotLayoutService";
import { IdiotMoveMethods } from "./idiotMoveMethods";
import { IdiotPlayResult } from "./idiotPlayResult";
import { StatisticsClass } from "../statistics";
import { PlayResult } from "../playResult";
import { SolitaireState } from "../solitaireState";
import { IdiotBlockMethods } from "./idiotBlockMethods";

export class IdiotSolitaire extends Solitaire<IdiotSolitaire> implements ISolitaire {
    prepareSolitaireState(state: Readonly<State>): SolitaireState<IdiotSolitaire> {
        return new SolitaireState<IdiotSolitaire>({
            statistics: new StatisticsClass<IdiotSolitaire>(),
            total: 0, 
            topNumberOfStepsRequired: 0,
            version: "0.1.0"}
        );
    }

    maxHistoryLength: number = 10000;
    defaultNumberOfGames: number = 1;
    checkAllPreviousSolutionStrings: boolean = true;
    saveSolutionStringsForUnwinnableLayouts: boolean = true;
    play(steps: number, state: Readonly<SolitaireState<IdiotSolitaire>>): PlayResult<IdiotSolitaire> {
        return this.getRunService().playWithLog(IdiotSolitaire, IdiotLayoutService, IdiotMoveMethods, IdiotBlockMethods, IdiotPlayResult, steps, state);
    }
    name: string = "The Idiot";
}
