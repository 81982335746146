import { SolitaireMove } from "../solitaireMove";
import { IdiotSolitaire } from "./idiotSolitaire";

export class MoveToEmptyColumn extends SolitaireMove<IdiotSolitaire> {
    fromColumn!: number;
    toColumn!: number;
    isTrivialMove!: boolean;
    isTrivial() : boolean {
        return this.isTrivialMove;
    }
    constructor(init?: Partial<MoveToEmptyColumn>) {
        super();
        return Object.assign(this, init);
    }
}

export class DealMove extends SolitaireMove<IdiotSolitaire> {}

export class DiscardTopCardOfColumnMove extends SolitaireMove<IdiotSolitaire> {
    columnToRemoveTopCardFrom: number;
    constructor(columnToRemoveTopCardFrom: number) {
        super();
        this.columnToRemoveTopCardFrom = columnToRemoveTopCardFrom;
    }
}