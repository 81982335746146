import { IdiotSolutionSet } from "../Models/Idiot/idiotSolutionSet";
import { IdiotLayout } from "../Models/Idiot/idiotLayout";
import { LayoutService } from "./layoutService";
import { IdiotSolitaire } from "../Models/Idiot/idiotSolitaire";

export class IdiotLayoutService extends LayoutService<IdiotSolitaire, IdiotLayout>
{
    organizeInitialLayout(deck: number[]): IdiotLayout {
        if (deck.length !== 52) { Error("Not a valid deck!"); }

        var stockPile : number[][] = [];
        for (var i = 0; i < 13; i++)
        {
            var j = 4 * i;
            stockPile.push(deck.slice(j, j + 4));
        }
        
        let solutionSet = new IdiotSolutionSet({columns: [[],[],[],[]], stockPile: stockPile});
        return new IdiotLayout({columns: [[],[],[],[]], stockPile: stockPile, discardedOfSuit: [[],[],[],[]], solutionSet: solutionSet});
    }
}
