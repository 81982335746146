import { ISolitaire } from '../Models/solitaire'
import { ISolutionSet } from '../Models/solutionSet'
import { Newable } from '../newable';

export interface IBaseSolitaireLayout<TSolitaire extends ISolitaire>
{
    columns: number[][];
    stockPile: number[][] | null;
}

export abstract class BaseSolitaireLayout<TSolitaire extends ISolitaire>
{
    columns!: number[][];
    stockPile: number[][] | null = null;
}

export interface ISolitaireLayoutOutput<TSolitaire extends ISolitaire>
extends IBaseSolitaireLayout<TSolitaire> {
    faceDownCards: number[][];
}

export abstract class SolitaireLayoutInternal<TSolitaire extends ISolitaire, TSolutionSet extends ISolutionSet<TSolitaire>> 
    extends BaseSolitaireLayout<TSolitaire> implements IBaseSolitaireLayout<TSolitaire> 
{
    solutionSet!: TSolutionSet;
    
    abstract preserialize() : object[];

    constructorFunction<T extends SolitaireLayoutInternal<TSolitaire, TSolutionSet>>(solutionSetType: Newable<TSolutionSet>, init?: Partial<T>) {
        if (init?.solutionSet != null) init.solutionSet = new solutionSetType(init.solutionSet);
        return Object.assign(this, init);
    }
    cloneFunction<T extends SolitaireLayoutInternal<TSolitaire, TSolutionSet>>(type: Newable<T>): T {
        return new type(JSON.parse(JSON.stringify(this)));
    }
}