import { ISolitaireLayoutOutput } from "./baseSolitaireLayout";
import { ISolitaire } from "./solitaire";
import { StatisticsClass } from "./statistics";

export class SolitaireState<TSolitaire extends ISolitaire> implements ISolitaireStateWithStatistics<TSolitaire>
{
    public constructor(init?: Partial<SolitaireState<TSolitaire>>) {
        var result = Object.assign(this, init);
        return result;
    }
    statistics!: StatisticsClass<TSolitaire>;
    defaultNumberOfGames!: number;
    topNumberOfStepsRequired: number | undefined;
    total: number = 0;  
    version!: string;
}

export interface ISolitaireStateWithStatistics<TSolitaire extends ISolitaire> extends ISolitaireState<TSolitaire> {
    statistics: StatisticsClass<TSolitaire>;
}

export interface ISolitaireState<TSolitaire extends ISolitaire> extends IBaseSolitaireState {
}

export interface ISolitaireStateWithVerifications<TSolitaire extends ISolitaire> extends ISolitaireStateWithStatistics<TSolitaire> {
    verifications: Verifications;
}

export interface ISolitaireStateWithSimulations<TSolitaire extends ISolitaire, TLayout extends ISolitaireLayoutOutput<TSolitaire>> extends ISolitaireStateWithStatistics<TSolitaire> {
    simulations: SimulationsFor<TSolitaire, TLayout>;
}


export class SolitaireStateWithVerifications<TSolitaire extends ISolitaire> extends SolitaireState<TSolitaire> implements ISolitaireStateWithVerifications<TSolitaire> {
    verifications: Verifications = new Verifications();    

    public constructor(init?: Partial<SolitaireStateWithVerifications<TSolitaire>>) {
        super(init);
        var result = Object.assign(this, init);
        return result;
    }
}
export class SolitaireStateWithVerificationsAndSimulations<TSolitaire extends ISolitaire, TLayout extends ISolitaireLayoutOutput<TSolitaire>>
    extends SolitaireStateWithVerifications<TSolitaire>
    implements ISolitaireStateWithSimulations<TSolitaire, TLayout> {
    simulations: SimulationsFor<TSolitaire, TLayout> = new SimulationsFor<TSolitaire, TLayout>();

    public constructor(init?: Partial<SolitaireStateWithVerificationsAndSimulations<TSolitaire, TLayout>>) {
        super(init);
        var result = Object.assign(this, init);
        return result;
    }
}

export interface IBaseSolitaireState {
    // hasStatistics: <TSolitaire extends ISolitaire> () => this is ISolitaireStateWithStatistics<TSolitaire>;
    // belongsToSolitaire: <TSolitaire extends ISolitaire> () => this is ISolitaireState<TSolitaire>;
    // canBeVerified: <TSolitaire extends ISolitaire & ICanBeVerified<TSolitaire>>() => this is SolitaireStateWithVerifications<TSolitaire>;
    defaultNumberOfGames: number;
    topNumberOfStepsRequired: number | undefined;
    total: number;  
    version: string;
}

export class Verifications {
    resultsToVerifyAsJson: string = "[]";
    amountOfVerificationsSucceeded: number = 0;
    amountOfVerificationsNotSucceeded: number = 0;
    verificationsNotSucceededAsJson: string = "[]";
}

export class Simulations {
    simulationToRun: string = "";
    simulationIndex: number = 0;
}

export class SimulationsFor<TSolitaire extends ISolitaire, TLayout extends ISolitaireLayoutOutput<TSolitaire>> extends Simulations {
    historyResult: TLayout[] | null = null;
}
