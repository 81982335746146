import { IVerificationLayoutService, LayoutService } from "./layoutService";
import { ScorpionLayout } from "../Models/Scorpion/scorpionLayout";
import { ScorpionSolutionSet } from "../Models/Scorpion/scorpionSolutionSet";
import { ScorpionSolitaire } from "../Models/Scorpion/scorpionSolitaire";
import { ScorpionVerificationModel } from "../Models/Scorpion/scorpionVerificationModel";
import { Card } from "../card";

export class ScorpionLayoutService 
    extends LayoutService<ScorpionSolitaire, ScorpionLayout>
    implements
    IVerificationLayoutService<ScorpionSolitaire, ScorpionVerificationModel, ScorpionLayout>
    {
    
    convertToInitialLayout(verificationModel: ScorpionVerificationModel): ScorpionLayout {
        return this.convertOrganizationToInitialLayout(verificationModel.columns, verificationModel.leftovers);
    }

    convertOrganizationToInitialLayout(columns: number[][], leftovers: number[]): ScorpionLayout {
        let result = new ScorpionLayout({ columns: [], leftovers: leftovers, faceDownCards: [] }); 
        for (let i = 0; i < 7; i++) {
            var column = columns[i];
            result.columns.push(column);
            if (i < 4) {
                result.faceDownCards.push(column.slice(0, 3));
            }
        }
        result.solutionSet = this.calculateSolutionSet(result);
        return result;
    }
  

    calculateSolutionSet(columnsAndLeftovers: ScorpionLayout) : ScorpionSolutionSet {
        let p = [];
        let w : { [key: number]: number | string } = {};
        let leftoverColumns = ScorpionSolitaire.leftoverColumns;
        for (let i = 0; i < columnsAndLeftovers.columns.length; i++) {
            let c = columnsAndLeftovers.columns[i];
            let kingAtBottom = Card.isKing(c[0]);
            if (leftoverColumns.indexOf(i) > -1) {
                if (c.length === 0) w[i] = 0;
                if (c.length > 1) w[i] = kingAtBottom ? c[0] : "-";
            }
            let start = 0;
            if (start === 0 && kingAtBottom) p.push(c[0]);
            for (let j = start + 1; j < c.length; j++) {
                if (c[j - 1] + 1 === c[j] && !Card.isKing(c[j])) p.push(c[j]);
            }
        }
        return new ScorpionSolutionSet({solved: p.sort((a, b) => a - b), leftovers: {}, winnabilityHelper: w});
    }

    organizeInitialLayout(deck: number[]): ScorpionLayout {
        if (deck.length !== 52) { Error("Not a valid deck!"); }
        let firstOrganize = this.firstOrganize(deck);
        return this.convertOrganizationToInitialLayout(firstOrganize.slice(0, 7), firstOrganize[7]);
        // let result = new ScorpionColumnsAndLeftovers({ columns: [], leftovers: deck.slice(49, 52), faceDownCards: [] }); 
        // for (let i = 0; i < 7; i++) {
        //     let j = 7 * i;
        //     result.columns.push(deck.slice(j, j + 7));
        //     if (i < 4) {
        //         result.faceDownCards.push(deck.slice(j, j + 3));
        //     }
        // }
        // result.solutionSet = result.calculateSolutionSet();
        // return result;
    }

    firstOrganize(deck: number[]) : number[][] {
        let organization : number[][] = [];
        for (let i = 0; i <= 7; i++) {
            let j = 7 * i;
            organization.push(deck.slice(j, i < 7 ? (j + 7) : 52));
        }
        return organization;
    }
}