import { MoveConsequence } from "../moveConsequence";
import { IdiotLayout } from "./idiotLayout";
import { IdiotSolitaire } from "./idiotSolitaire";

export class IdiotMoveConsequence extends MoveConsequence<IdiotSolitaire, IdiotLayout> {
    wasDealMove: boolean;
    constructor(step: IdiotLayout, wasDealMove: boolean) {
        super(step);
        this.wasDealMove = wasDealMove;
    }
}
