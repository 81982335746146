import { ScorpionSolitaire } from "./scorpionSolitaire";
import { SolitaireMove } from "../solitaireMove";

export class LeftoverMove extends SolitaireMove<ScorpionSolitaire> {}

export class MoveToColumn extends SolitaireMove<ScorpionSolitaire> {
    fromColumn: number;
    fromColumnPosition: number;
    toColumn: number;
    lengthOfFromColumn: number;
    lengthOfToColumn: number;
    isTrivialKingMove: boolean;
    isTrivial() : boolean {
        return this.isTrivialKingMove;
    }
    constructor(
        fromColumn: number,
        fromColumnPosition: number,
        toColumn: number,
        lengthOfFromColumn: number,
        lengthOfToColumn: number,
        isTrivialKingMove: boolean) {
            super();
            this.fromColumn = fromColumn;
            this.fromColumnPosition = fromColumnPosition;
            this.toColumn = toColumn;
            this.lengthOfFromColumn = lengthOfFromColumn;
            this.lengthOfToColumn = lengthOfToColumn;
            this.isTrivialKingMove = isTrivialKingMove;
        }
}