import { ICanBeSimulated, ISolitaire } from "./Models/solitaire";
import { PlayResult, PlayResultWithLog, PlayResultWithWinningHistory, SimulationResult, VerificationResult } from "./Models/playResult";
import { Card } from "./card";
import { RunLog } from "./Models/runLog";
import { SolitaireLayoutOverview } from "./Models/solitaireLayoutOverview";
import { LayoutService } from "./Services/layoutService";
import { ISolitaireLayoutOutput, SolitaireLayoutInternal } from "./Models/baseSolitaireLayout";
import { ISolitaireLayout } from "./Models/solitaireLayout";
import { ISolutionSet } from "./Models/solutionSet";
import { ResultBody } from "./Models/resultBody";
import { Helper } from "./helper";
import { MoveMethods } from "./Models/moveMethods";
import { MoveConsequence } from "./Models/moveConsequence";
import { Time } from "./time";
import { Failures } from "./Models/failures";
import { FailureHistory } from "./Models/failureHistory";
import { Newable } from "./newable";
import { Outcome } from "./Models/outcome";
import { SimulationsFor, SolitaireState } from "./Models/solitaireState";
import { IVerificationModel } from "./Models/verificationModel";
import { IBlockMethods } from "./Models/blockMethods";
import { ISimulationModel } from "./Models/simulationModel";

export class RunService<TSolitaire extends ISolitaire, TRunLog extends RunLog, 
TLayoutOverview extends SolitaireLayoutOverview<TSolitaire, TLayout, TSolutionSet>,    
TLayout extends SolitaireLayoutInternal<TSolitaire, TSolutionSet> & ISolitaireLayout<TSolitaire>,
TMoveMethods extends MoveMethods<TSolitaire, TMoveConsequence, TLayout, TSolutionSet, TResultBody, TRunLog>,
TBlockMethods extends IBlockMethods<TSolitaire, TLayout, TResultBody>,
TMoveConsequence extends MoveConsequence<TSolitaire, TLayout>,
TSolutionSet extends ISolutionSet<TSolitaire>,
TResultBody extends ResultBody<TSolitaire>>
{

    playWithLog<TLayoutService extends LayoutService<TSolitaire, TLayout>,
    TPlayResult extends PlayResultWithLog<TSolitaire, TRunLog>>
    (solitaireType: Newable<TSolitaire>, serviceType: Newable<TLayoutService>,
    methodsType: Newable<TMoveMethods>, blockMethodsType: Newable<TBlockMethods>, resultType: Newable<TPlayResult>,
    limitSteps: number, solitaireState: Readonly<SolitaireState<TSolitaire>>) : TPlayResult {
        var shuffledDeck = this.newShuffledDeck();

        var layoutService = new serviceType();
        var originalLayout = layoutService.organizeInitialLayout(shuffledDeck);

        var methods = new methodsType();
        var simulationResult = this.playLayout(solitaireType, methods, blockMethodsType, limitSteps, originalLayout);

        return this.convertToPlayResultWithLog(resultType, methods, originalLayout, simulationResult, solitaireState, limitSteps);
    }

    playLayout(solitaireType: Newable<TSolitaire>, 
        methods: TMoveMethods, 
        blockMethodsType: Newable<TBlockMethods>, 
        limitSteps: number, originalLayout: TLayout) 
    : SimulationResult<TSolitaire, TLayoutOverview, TLayout, TSolutionSet, TResultBody> {
        var layoutOverviewTypeContent = {
            columnsAndLeftovers: originalLayout,
            trivialMovesPerformed: 0,
            possibleMoves: methods.investigatePossibleMoves(originalLayout),
            movesTriedOut: [] as number[],
            exhaustedAllPossibleMoves: false
        } as TLayoutOverview;

        Helper.log("=======================================");

        let solitaire = new solitaireType();
        let initialTableau = originalLayout.preserialize();
        Helper.log("Initiating simulation of deck " + JSON.stringify(initialTableau));

        let blockMethods = new blockMethodsType();

        var simulationResult = this.runSimulation(solitaire, methods, blockMethods, originalLayout, limitSteps, layoutOverviewTypeContent);

        let history = simulationResult.history;
        let steps = simulationResult.steps;
        let isGamePossibleToWinFromOutset = simulationResult.isGamePossibleToWinFromOutset;
        let milliseconds = simulationResult.milliseconds;
        let outcome = simulationResult.outcome;

        // Final logging    
        let stepsStr = steps.toLocaleString();
        let layoutsThatWillLeadToFailure = simulationResult.layoutsThatWillLeadToFailure;
        let failurePatterns = layoutsThatWillLeadToFailure.length;
        let hitsAndQuits = layoutsThatWillLeadToFailure.hitItAndQuitIt;
        let hitsAndQuitsStr = hitsAndQuits.toLocaleString();
        if (isGamePossibleToWinFromOutset.result) {
            if (outcome === Outcome.Won) {
                //Helper.log(history);
                if (failurePatterns === 0) { Helper.log("Won in " + steps.toLocaleString() + " easy steps!"); } else { Helper.log("Won despite failing more than " + failurePatterns.toLocaleString() + " times, in " + stepsStr + " steps! Hit a failing pattern " + hitsAndQuitsStr + " times"); }
                if (history[history.length - 1].trivialMovesPerformed > 0) { Helper.log("Moreover, this game was won after using a trivial move!"); }
            } 
            else {
                if (outcome === Outcome.Undetermined) {
                    Helper.log("Failed to win in allocated number of steps (" + limitSteps.toLocaleString() + "). " +
                        "Hit a failing pattern " + hitsAndQuitsStr + " times; there were " + failurePatterns.toLocaleString() + " failing patterns in total");
                } 
                else if (outcome === Outcome.Lost) {
                    Helper.log("Lost by exhausting all possibilities in " + stepsStr + " steps! Hit a failing pattern " + hitsAndQuitsStr + " times");
                }
            }
            Helper.log("Duration of simulation: " + Time.convertFromMilliseconds(milliseconds));
        } else { 
            Helper.log("Game was unwinnable, proceeding...."); 
        }

        return simulationResult;
    }


    convertToPlayResultWithLog<TPlayResult extends PlayResultWithLog<TSolitaire, TRunLog>>(resultType: Newable<TPlayResult>,
        methods: TMoveMethods, originalLayout: TLayout,
        simulationResult: SimulationResult<TSolitaire, TLayoutOverview, TLayout, TSolutionSet, TResultBody>,
        solitaireState: SolitaireState<TSolitaire>, steps: number) : TPlayResult
    {
        let result = this.convertToPlayResult<TPlayResult>(resultType, originalLayout, simulationResult, solitaireState, steps);
        result.log = methods.getResultBody(simulationResult.isGamePossibleToWinFromOutset, simulationResult.failureHistory);
        return result;
    }

    convertToPlayResultWithWinningHistory<TPlayResult extends PlayResultWithWinningHistory<TSolitaire, TLayoutOutput>,
    TLayoutOutput extends ISolitaireLayoutOutput<TSolitaire>, TSimulationModel extends ISimulationModel<TSolitaire>>
    (solitaire: TSolitaire & ICanBeSimulated<TSolitaire, TLayout, TLayoutOutput, TSimulationModel>, resultType: Newable<TPlayResult>, layoutOutputType: Newable<TLayoutOutput>, 
        originalLayout: TLayout,
        simulationResult: SimulationResult<TSolitaire, TLayoutOverview, TLayout, TSolutionSet, TResultBody>,
        solitaireState: SolitaireState<TSolitaire>, steps: number) : TPlayResult {
        let result = this.convertToPlayResult(resultType, originalLayout, simulationResult, solitaireState, steps);
        if (result.outcome === Outcome.Won || (result.outcome === Outcome.Undetermined && result.hitMaxHistoryLength)) {
            result.simulationsOutput = new SimulationsFor<TSolitaire, TLayoutOutput>();
            let historyResult : TLayoutOutput[] = [];
            for (var i = 0; i < simulationResult.history.length; i++) {
                var step = simulationResult.history[i].columnsAndLeftovers;
                historyResult.push(solitaire.convertOutputToSimulationOutput(step));
            }
            result.simulationsOutput.historyResult = historyResult;
        } else {
            result.simulationsOutput = null;
        }
        return result;
    }

    
    convertToPlayResult<TPlayResult extends PlayResult<TSolitaire>>
    (resultType: Newable<TPlayResult>,
        originalLayout: TLayout,
        simulationResult: SimulationResult<TSolitaire, TLayoutOverview, TLayout, TSolutionSet, TResultBody>,
        solitaireState: SolitaireState<TSolitaire>, steps: number) : TPlayResult {
        
        let isGamePossibleToWinFromOutset = simulationResult.isGamePossibleToWinFromOutset;
        let topLength = simulationResult.maximalDistanceFromInitialTableau;
        // Update session statistics
        let resultStatistics = this.getNewStatistics(simulationResult.outcome,
            isGamePossibleToWinFromOutset.result, topLength, solitaireState, steps);

        let result = Helper.init<PlayResult<TSolitaire>, TPlayResult>(resultType, { 
            initialTableau: originalLayout,
            maximalDistanceFromInitialTableau: topLength,
            startTime: simulationResult.startTime,
            endTime: simulationResult.endTime,
            milliseconds: simulationResult.milliseconds,
            steps: simulationResult.steps,
            amountOfFailures: simulationResult.layoutsThatWillLeadToFailure.length,
            hitsAndQuits: simulationResult.layoutsThatWillLeadToFailure.hitItAndQuitIt,
            outcome: simulationResult.outcome,
            version: solitaireState.version,
            statistics: resultStatistics,
            hitMaxHistoryLength: simulationResult.hitMaxHistoryLength
        });
        return result;
    }

    getNewStatistics(outcome: Outcome, isGamePossibleToWinFromOutsetResult: boolean,
        topLength: number,
        solitaireState: Readonly<SolitaireState<TSolitaire>>,
        steps: number) : SolitaireState<TSolitaire> {

        let statistics = solitaireState.statistics.clone();
        if (outcome === Outcome.Won) { statistics.won++; }
        else if (outcome === Outcome.Lost) { statistics.lost++; }
        else if (outcome === Outcome.Unwinnable) { statistics.unwinnable++; }
        else { statistics.undetermined++; }

        //let outcomeObject = { 1: 'won', 2: 'lost', 3: 'unwinnable', 4: 'undetermined' };
        if (isGamePossibleToWinFromOutsetResult) {
            if (!statistics.games[outcome]) statistics.games[outcome] = {};
            statistics.games[outcome][topLength] =
                statistics.games[outcome][topLength] ? statistics.games[outcome][topLength] + 1 : 1;
        }
        let topNumberOfStepsRequired = solitaireState.topNumberOfStepsRequired === undefined || solitaireState.topNumberOfStepsRequired < topLength ? topLength : solitaireState.topNumberOfStepsRequired;
        var resultStatistics = new SolitaireState();
        resultStatistics.total = solitaireState.total + 1;
        resultStatistics.statistics = statistics;
        resultStatistics.topNumberOfStepsRequired = topNumberOfStepsRequired;
        resultStatistics.version = solitaireState.version;
        return resultStatistics;
    }

    
    runSimulation(solitaire: TSolitaire, 
        methods: TMoveMethods,
        blockMethods: TBlockMethods,
        originalLayout: TLayout,
        limitSteps: number,
        initialLayoutOverview: TLayoutOverview) : SimulationResult<TSolitaire, TLayoutOverview, TLayout, TSolutionSet, TResultBody> {
        let steps = 0;
        let topLength = 0;

        let startTime = new Date();
        let history = [initialLayoutOverview];

        let layoutsThatWillLeadToFailure = new Failures();
        let failureHistory = new FailureHistory();
        let isGamePossibleToWinFromOutset = blockMethods.isGamePossibleToWinFromOutset(originalLayout, true) as TResultBody;
        let isGamePossibleToWinFromOutsetResult = isGamePossibleToWinFromOutset.result;
        let hitMaxHistoryLength = false;
        if (isGamePossibleToWinFromOutsetResult) {
            let ratio = limitSteps / 20 > 5000000 ? 5000000 : limitSteps / 20;
            while (limitSteps > steps && !history[history.length - 1].columnsAndLeftovers.isWon() && !initialLayoutOverview.exhaustedAllPossibleMoves) {
                if (history.length > solitaire.maxHistoryLength) { hitMaxHistoryLength = true; break; } // This should not happen!
                if (steps > 20000 && history.length === 1 && history[0].movesTriedOut.length > 0 && history[0].movesTriedOut.length < history[0].possibleMoves.length) Helper.log("Finished base branch " + history[0].movesTriedOut.length + " out of " + history[0].possibleMoves.length + ", after " + steps.toLocaleString() + " steps");
                if (steps > 100000 && history.length === 2 && history[1].movesTriedOut.length > 0 && history[1].movesTriedOut.length < history[1].possibleMoves.length) Helper.log("Finished sub-branch " + history[1].movesTriedOut.length + " out of " + history[1].possibleMoves.length + " in base branch " + history[0].movesTriedOut.length + ", after " + steps.toLocaleString() + " steps");
                if (steps > 500000 && history.length === 3 && history[2].movesTriedOut.length > 0 && history[2].movesTriedOut.length < history[2].possibleMoves.length) Helper.log("Finished sub-branch " + history[2].movesTriedOut.length + " out of " + history[2].possibleMoves.length + " in sub-branch " + history[1].movesTriedOut.length + " of base branch " + history[0].movesTriedOut.length + ", after " + steps.toLocaleString() + " steps");
                if (steps > 0 && steps % ratio === 0) Helper.log(steps.toLocaleString() + " steps...");
                history = methods.moveCards(solitaire, history, layoutsThatWillLeadToFailure);
                if (topLength < history.length - 1) topLength = history.length - 1; // The number of moves is the length of the history bar the first element
                steps++;
                if (history.length <= 2) failureHistory = methods.addToFailureHistory(failureHistory, history, steps);
            }
        }
        
        let endTime = new Date();
        
        let isGameWon = history[history.length - 1].columnsAndLeftovers.isWon();        
        let outcome = isGameWon ? Outcome.Won : 
            (isGamePossibleToWinFromOutsetResult ?
                (steps < limitSteps && !hitMaxHistoryLength ? Outcome.Lost : Outcome.Undetermined)
                : Outcome.Unwinnable);

        var result = {
            maximalDistanceFromInitialTableau: topLength,
            startTime: startTime,
            endTime: endTime,
            milliseconds: Math.abs(endTime.getTime() - startTime.getTime()),
            isGamePossibleToWinFromOutset: isGamePossibleToWinFromOutset,
            steps: steps,
            history: history,
            layoutsThatWillLeadToFailure: layoutsThatWillLeadToFailure,
            failureHistory: failureHistory,
            outcome: outcome,
            hitMaxHistoryLength: hitMaxHistoryLength
        } as Partial<SimulationResult<TSolitaire, TLayoutOverview, TLayout, TSolutionSet, TResultBody>>;

        return new SimulationResult<TSolitaire, TLayoutOverview, TLayout, TSolutionSet, TResultBody>(result);
    }

    verify<TLayoutService extends LayoutService<TSolitaire, TLayout>,
    TVerificationResult extends VerificationResult<TSolitaire, TRunLog>, TVerificationModel extends IVerificationModel<TSolitaire>>
    (solitaireType: Newable<TSolitaire>, serviceType: Newable<TLayoutService>,
    methodsType: Newable<TMoveMethods>, blockMethodsType: Newable<TBlockMethods>, resultType: Newable<TVerificationResult>,
    limitSteps: number, solitaireState: Readonly<SolitaireState<TSolitaire>>, verificationModel: TVerificationModel) :
        TVerificationResult {
            
        var layoutService = new serviceType();
        if (!layoutService.canBeVerified()) {
            throw new Error("Solitaire cannot be verified: " + solitaireType.name);
        }
        var originalLayout = layoutService.convertToInitialLayout(verificationModel);
        var methods = new methodsType();

        var simulationResult = this.playLayout(solitaireType, methods, blockMethodsType, limitSteps, originalLayout);
        var result = this.convertToPlayResultWithLog(resultType, methods, originalLayout, simulationResult, solitaireState, limitSteps);
        result.verificationResult = result.outcome === verificationModel.outcome;
        return result;
    }
    
    simulate<TLayoutService extends LayoutService<TSolitaire, TLayout>,
    TSimulationResult extends PlayResultWithWinningHistory<TSolitaire, TBaseLayout>,
    TSimulationModel extends ISimulationModel<TSolitaire>,
    TBaseLayout extends ISolitaireLayoutOutput<TSolitaire>>
    (solitaireType: Newable<TSolitaire>, serviceType: Newable<TLayoutService>,
    methodsType: Newable<TMoveMethods>, blockMethodsType: Newable<TBlockMethods>, resultType: Newable<TSimulationResult>, layoutOutputType: Newable<TBaseLayout>,
    limitSteps: number, solitaireState: Readonly<SolitaireState<TSolitaire>>, simulationModel: TSimulationModel) :
        TSimulationResult {
        var solitaire = new solitaireType();
        if (solitaire.canBeSimulated<TSolitaire, TLayout, TBaseLayout, TSimulationModel>()) {
            var layoutService = new serviceType();
            if (!layoutService.canBeSimulated()) {
                throw new Error("Solitaire cannot be simulated: " + solitaireType.name);
            }
            var originalLayout = layoutService.convertToInitialLayout(simulationModel);  
            var methods = new methodsType();    

            var simulationResult = this.playLayout(solitaireType, methods, blockMethodsType, limitSteps, originalLayout);
            var result = this.convertToPlayResultWithWinningHistory(solitaire, resultType, layoutOutputType, originalLayout, simulationResult,  solitaireState, limitSteps);

            return result;
        } else {
            throw new Error("Solitaire cannot be simulated: " + solitaireType.name);
        }
    }

    newShuffledDeck() : number[] {
        var deck : number[] = [];
        for (var i = 1; i < 53; i++)
        {
            deck.push(i);
        }
        return Card.doFisherYatesShuffle(deck);
    }
    
}