import { ResultBody } from "../resultBody";
import { ScorpionBlockLog } from "./scorpionBlock";
import { ScorpionSolitaire } from "./scorpionSolitaire";

export class ScorpionResultBody extends ResultBody<ScorpionSolitaire>
{
    forLog: ScorpionBlockLog | null;

    constructor(forLog: ScorpionBlockLog | null, result: boolean) {
        super(result);
        this.forLog = forLog;
    }
}