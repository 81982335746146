import { SolitaireLayoutInternal } from '../baseSolitaireLayout'
import { ScorpionSolitaire } from "./scorpionSolitaire";
import { ISolitaireLayout } from '../solitaireLayout'
import { ScorpionSolutionSet } from './scorpionSolutionSet';
import { ICloneable } from '../../helper';
import { Card } from '../../card';
import { ScorpionColumnsAndLeftovers } from './scorpionColumnsAndLeftovers';

export class ScorpionLayout extends SolitaireLayoutInternal<ScorpionSolitaire, ScorpionSolutionSet>
 implements
    ISolitaireLayout<ScorpionSolitaire>,
    ICloneable<ScorpionLayout>, 
    ScorpionColumnsAndLeftovers {

    public constructor (init?:Partial<ScorpionLayout>) {
        super();
        if (init?.solutionSet != null) init.solutionSet = new ScorpionSolutionSet(init.solutionSet);
        Object.assign(this, init);
    }
    clone(): ScorpionLayout {
        return new ScorpionLayout(JSON.parse(JSON.stringify(this)));
    }

    preserialize() : object[] {
        return [ this.columns, this.leftovers ];
    };
    leftovers!: number[];
    faceDownCards!: number[][];
    
    isWon() : boolean {
        let columns = this.columns;
        for (let i = 0; i < columns.length; i++) {
            let c = columns[i];
            if (c.length > 0) {
                if (c.length !== 13) return false;
                for (let j = 0; j < 13; j++) {
                    if (!Card.hasValue(c[j], 13 - j)) return false;
                }
            }
        }
        return true;
    }
}