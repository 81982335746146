import { ISerializableModel } from "../serializableModel";
import { ScorpionSolitaire } from "./scorpionSolitaire";

export class ScorpionSerializableModel implements ISerializableModel<ScorpionSolitaire> {
    columns: number[][];
    leftovers: number[];
    constructor(content: object[]) {        
        this.columns = content[0] as number[][];
        this.leftovers = content[1] as number[];
    }
    preserialize(): object[] {
        return [this.columns, this.leftovers];
    }
}