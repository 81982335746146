import React, { ReactNode } from "react";
import { Container, Col, Row } from 'react-bootstrap';
import { Helper } from "../helper";
import * as CookieHelper from '../cookies';
import { LoginRequest } from "../Models/loginRequest";

export interface ILoginProps {
    onLoginSuccess: () => void;
}

export class LoginViewState {
    username: string = '';
    password: string = '';
}

export class LoginView extends React.Component<ILoginProps, LoginViewState> {

    constructor(props: ILoginProps) {
        super(props);
        this.state = new LoginViewState();
        
        this.handleLogin = this.handleLogin.bind(this);
        this._onUsernameChange = this._onUsernameChange.bind(this);
        this._onPasswordChange = this._onPasswordChange.bind(this);
    }

    handleLogin = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        var body = new LoginRequest(this.state.username, this.state.password);
        fetch('https://scorpion.ananas.nu/api/login', {
            method: 'POST',
            mode: 'cors',
            body: JSON.stringify(body),
            headers: {
                "Content-Type": "application/json; charset=UTF-8"
            }
        })
        .then(async function (response) { if (response.ok) { return response.json(); } else { throw new Error(await response.json()) } })
        .then((json) => {
            CookieHelper.setCookie("accessToken", json.accessToken, 1);
            this.props.onLoginSuccess();
            this.setState({password: ''})
            var loginButton = document.querySelector(".login-button");
            if (loginButton != null) loginButton.classList.add('success-button');
            Helper.log("You have logged in successfully");
        })
        .catch(() => {
            var loginButton = document.querySelector(".login-button");
            if (loginButton != null) {loginButton.classList.add('error-button'); }
            setTimeout(function () { 
                if (loginButton != null) {loginButton.classList.remove('on-fail');
            }}, 3000);
        }
        );
    }

    _onUsernameChange(e: React.FormEvent<HTMLInputElement>) {
        this.setState({ username: e.currentTarget.value });
    }

    _onPasswordChange(e: React.FormEvent<HTMLInputElement>) {
        this.setState({ password: e.currentTarget.value });
    }

    render() : ReactNode {
        return <form onSubmit={this.handleLogin}>
            <Container style={{ flexGrow: 1, justifyContent: 'center', alignItems: 'center' }}>
                <Row>
                    <Col sm />
                    <Col sm className="text-left">
                        <h5 className="card-title">Login</h5>
                    </Col>
                </Row>
                <div id="login-form">
                    <Row style={{ alignItems: 'center' }}>
                        <Col sm className=" text-right form-group">Username:</Col>
                        <Col sm className="text-left form-group">
                            <input
                                type="text"
                                placeholder="Enter your username"
                                className="form-control"
                                value={this.state.username}
                                onChange={evt => this._onUsernameChange(evt)}
                            />
                        </Col>
                    </Row>
                    <Row style={{ alignItems: 'center' }}>
                        <Col sm className="text-right form-group">Password:</Col>
                        <Col sm className="text-left form-group">
                            <input
                                type="password"
                                placeholder="Enter your password"
                                className="form-control"
                                value={this.state.password}
                                onChange={evt => this._onPasswordChange(evt)}
                            />
                        </Col>
                    </Row>
                    <Row style={{ alignItems: 'center' }}>
                        <Col sm></Col>
                        <Col sm className="text-left form-group">
                            <input type="submit" className="btn btn-primary login-button" value="Login" />
                        </Col>
                    </Row>
                </div>
            </Container>
        </form>
        }
    }