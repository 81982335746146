import { ISolitaire } from '../Models/solitaire'
import { ISolitaireLayout } from '../Models/solitaireLayout'
import { IVerificationModel } from '../Models/verificationModel'
import { IBaseSolitaireLayout } from '../Models/baseSolitaireLayout';
import { ISimulationModel } from '../Models/simulationModel';

export interface ILayoutService<TSolitaire extends ISolitaire,
TLayout extends IBaseSolitaireLayout<TSolitaire> & ISolitaireLayout<TSolitaire>> {
    canBeVerified: <TVerificationModel extends IVerificationModel<TSolitaire>>() => 
        this is IVerificationLayoutService<TSolitaire, TVerificationModel, TLayout>;
}

export abstract class LayoutService<TSolitaire extends ISolitaire,
TLayout extends IBaseSolitaireLayout<TSolitaire> & ISolitaireLayout<TSolitaire>>
    implements ILayoutService<TSolitaire, TLayout>
{
    canBeVerified<TVerificationModel extends IVerificationModel<TSolitaire>>() :
        this is IVerificationLayoutService<TSolitaire, TVerificationModel, TLayout> {
        return 'convertToInitialLayout' in this;
    }
    canBeSimulated<TSimulationModel extends ISimulationModel<TSolitaire>>() :
        this is IVerificationLayoutService<TSolitaire, TSimulationModel, TLayout> {
        return 'convertToInitialLayout' in this;
    }
    abstract organizeInitialLayout(deck: number[]): TLayout;
}

export interface IVerificationLayoutService<TSolitaire extends ISolitaire,
TVerificationModel extends ISimulationModel<TSolitaire>, 
TLayout extends IBaseSolitaireLayout<TSolitaire> & ISolitaireLayout<TSolitaire>> {
    convertToInitialLayout(initialTableau: TVerificationModel) : TLayout;
}