import { GenericDictionary } from "./dictionary";

export class Failures {
    dictionary: GenericDictionary = new GenericDictionary();
    length: number = 0;
    hitItAndQuitIt: number = 0;
    addLayoutToFailures(string: string) : void {
        let i = 0;
        let nextLevel = this.dictionary;
        while (i < string.length) {
            nextLevel[string[i]] = nextLevel[string[i]] || new GenericDictionary();
            nextLevel = nextLevel[string[i]];
            i++;
        }
        this.length++;
    }
    isContainedInFailures(string: string) : boolean {
        let nextLevel = this.dictionary;
        let couldBeAnExistingFailure = true;
        let i = 0;
        while (i < string.length && couldBeAnExistingFailure) {
            if (Object.keys(nextLevel).indexOf(string[i]) === -1) {
                couldBeAnExistingFailure = false;
            } else {
                nextLevel = nextLevel[string[i]];
                i++;
            }
        }
        return couldBeAnExistingFailure;
    }
}