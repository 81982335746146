import { IStatisticsCanBeSaved, IStatisticsCanBeRetrieved, ISolitaire, SolitaireThatCanBeVerifiedAndSimulated } from "../solitaire";
import { ScorpionLayoutService } from "../../Services/scorpionLayoutService";
import { ScorpionMoveMethods } from "./scorpionMoveMethods";
import { ScorpionPlayResult, ScorpionPlayResultWithWinningHistory } from "./scorpionPlayResult";
import { State } from "../state";
import { StatisticsClass } from "../statistics";
import { Outcome } from "../outcome";
import { Statistics } from "../../statisticsMethods";
import { ScorpionStatisticsResponse } from "./scorpionStatisticsResponse";
import { SolitaireState, SolitaireStateWithVerifications, SolitaireStateWithVerificationsAndSimulations } from "../solitaireState";
import { ScorpionVerificationModel } from "./scorpionVerificationModel";
import { ScorpionVerificationResult } from "./scorpionVerificationResult";
import { ScorpionLayout } from "./scorpionLayout";
import { ScorpionBlockMethods } from "./scorpionBlockMethods";
import { ScorpionSimulationModel } from "./scorpionSimulationModel";
import { ScorpionColumnsAndLeftovers } from "./scorpionColumnsAndLeftovers";
import { ScorpionRunLog } from "./scorpionRunLog";
import { PlayResult } from "../playResult";

export class ScorpionSolitaire extends SolitaireThatCanBeVerifiedAndSimulated
    <ScorpionSolitaire, ScorpionLayout, ScorpionColumnsAndLeftovers, ScorpionVerificationModel, ScorpionSimulationModel, ScorpionVerificationResult, ScorpionRunLog>
    implements
        ISolitaire,
        IStatisticsCanBeSaved<ScorpionSolitaire>,
        IStatisticsCanBeRetrieved<ScorpionStatisticsResponse, ScorpionSolitaire> {
            
    prepareSolitaireState(state: Readonly<State>): SolitaireStateWithVerificationsAndSimulations<ScorpionSolitaire, ScorpionColumnsAndLeftovers> {
        return new SolitaireStateWithVerificationsAndSimulations<ScorpionSolitaire, ScorpionColumnsAndLeftovers>({
            statistics: new StatisticsClass<ScorpionSolitaire>(),
            total: 0, 
            topNumberOfStepsRequired: 0,
            version: "0.9.1"
        });
    }
    convertStatisticsApiResponseToStateStats(json: ScorpionStatisticsResponse, oldState: SolitaireStateWithVerifications<ScorpionSolitaire>): SolitaireStateWithVerifications<ScorpionSolitaire> {
        let games = StatisticsClass.getNewGames();
        let topNumberOfStepsRequired = 0;
        for (let i = 0; i < Object.keys(json.winnable).length; i++) {
            let key = Object.keys(json.winnable)[i];
            let keyAsOutcome : Outcome = Outcome[key.replace(/^./, str => str.toUpperCase()) as keyof typeof Outcome];
            let group = json.winnable[key];
            for (let j = 0; j < Object.keys(group).length; j++) {
                let lth = +Object.keys(group)[j];
                if (isNaN(lth)) continue;
                let value = group[lth];
                games[keyAsOutcome][lth] = value["count"];
                if (topNumberOfStepsRequired < value["max_steps"]) {topNumberOfStepsRequired = value["max_steps"]; }
            }
        }
        return new SolitaireStateWithVerifications<ScorpionSolitaire>({
            defaultNumberOfGames: oldState.defaultNumberOfGames,
            statistics: new StatisticsClass({
                won: json.winnable.won.total,
                lost: json.winnable.lost.total,
                unwinnable: json.unwinnable.total,
                undetermined: json.winnable.undetermined.total,
                games: games,                    
                percentageString: Statistics.calculateWinPercentage(json.winnable.won.total, json.total)
            }),
            topNumberOfStepsRequired: topNumberOfStepsRequired,
            total: json.total,
            version: oldState.version,
            verifications: oldState.verifications
        });
    }
    savePlayResultApiUrl: string = 'https://scorpion.ananas.nu/api/run';
    saveFilter(playResult: PlayResult<ScorpionSolitaire>): boolean {
        return playResult.hasLog();
    }
    getPlayResultApiUrl(version: string): string { 
        return 'https://scorpion.ananas.nu/api/statistics?version=' + version;
    }
    play(steps: number, statistics: Readonly<SolitaireState<ScorpionSolitaire>>): ScorpionPlayResult {
        return this.getRunService().playWithLog(ScorpionSolitaire, ScorpionLayoutService, ScorpionMoveMethods, ScorpionBlockMethods, ScorpionPlayResult, steps, statistics);
    }
    verify(steps: number, statistics: Readonly<SolitaireState<ScorpionSolitaire>>, verificationModel: ScorpionVerificationModel): 
        ScorpionVerificationResult {
        return this.getRunService().verify(ScorpionSolitaire, ScorpionLayoutService, ScorpionMoveMethods, ScorpionBlockMethods, ScorpionVerificationResult, steps, statistics, verificationModel);
    }
    simulate(steps: number, statistics: Readonly<SolitaireState<ScorpionSolitaire>>, simulationModel: ScorpionSimulationModel): 
        ScorpionPlayResultWithWinningHistory {
        return this.getRunService().simulate(ScorpionSolitaire, ScorpionLayoutService, ScorpionMoveMethods, ScorpionBlockMethods, ScorpionPlayResultWithWinningHistory, ScorpionColumnsAndLeftovers, steps, statistics, simulationModel);
    }
    convertJsonToVerificationModels(json: string) : Array<ScorpionVerificationModel> {
        let objects : {"outcome": string, "initialTableau": object[]}[] = JSON.parse(json);
        return objects.map(obj => new ScorpionVerificationModel
            (obj["initialTableau"], obj["outcome"]));
    }
    convertJsonToSimulationModel(json: string): ScorpionSimulationModel {
        let objects : object[] = JSON.parse(json);
        return new ScorpionSimulationModel(objects);
    }
    convertVerificationModelToJsonModel(model: ScorpionVerificationModel) : any {
        return {"outcome": model.outcome, "initialTableau": model.preserialize()}
    }
    convertOutputToSimulationOutput(output: ScorpionLayout) : ScorpionColumnsAndLeftovers {
        return { ...output } as ScorpionColumnsAndLeftovers;
    }
    
    maxHistoryLength: number = 150;
    defaultNumberOfGames: number = 1000;
    checkAllPreviousSolutionStrings: boolean = false;
    saveSolutionStringsForUnwinnableLayouts: boolean = true;
    static leftoverColumns: number[] = [0, 1, 2];
    name: string = "Scorpion";
}
