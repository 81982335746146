import { ISolitaire } from "../Models/solitaire";
import { IExtraStatisticsView, IHasExtraStatisticsView } from "./extraStatisticsView";

export abstract class SolitaireView<TSolitaire extends ISolitaire> implements ISolitaireView<TSolitaire> {
    isConnected<TSolitaire extends ISolitaire>() : this is ISolitaireView<TSolitaire> {
        return true;
    }
    hasExtraStatisticsView<TExtraStatisticsView extends IExtraStatisticsView>():
    this is IHasExtraStatisticsView<TExtraStatisticsView, TSolitaire> {
        return 'getExtraStatisticsView' in this;
    }
}

export interface ISolitaireView<TSolitaire extends ISolitaire> extends IBaseSolitaireView
{
    hasExtraStatisticsView: <TExtraStatisticsView extends IExtraStatisticsView>() => this is IHasExtraStatisticsView<TExtraStatisticsView, TSolitaire>;
}

export interface IBaseSolitaireView {
    isConnected: <TSolitaire extends ISolitaire>() => this is ISolitaireView<TSolitaire>;
}