import { MoveMethods } from "../moveMethods";
import { IdiotMoveConsequence } from "./idiotMoveConsequence";
import { IdiotLayout } from "./idiotLayout";
import { IdiotSolutionSet } from "./idiotSolutionSet";
import { IdiotResultBody } from "./idiotResultBody";
import { MoveToEmptyColumn, DiscardTopCardOfColumnMove, DealMove } from "./idiotMove";
import { SolitaireMove } from "../solitaireMove";
import { Card } from "../../card";
import { IdiotRunLog } from "./idiotRunLog";
import { FailureHistory } from "../failureHistory";
import { IdiotSolitaire } from "./idiotSolitaire";
import { IdiotBlockMethods } from "./idiotBlockMethods";

export class IdiotMoveMethods extends MoveMethods<IdiotSolitaire, IdiotMoveConsequence, IdiotLayout, IdiotSolutionSet, IdiotResultBody, IdiotRunLog>
{
    getResultBody(resultBody: IdiotResultBody, failureHistory: FailureHistory): IdiotRunLog {
        return new IdiotRunLog({history: failureHistory});
    }

    performMove(layout: IdiotLayout, theMoveToDo: SolitaireMove<IdiotSolitaire>) : IdiotMoveConsequence
    {
        if (layout == null) debugger;
        var cal = layout.clone();
        var newColumns = cal.columns;
        var newStockPile = cal.stockPile;
        var newDiscardedOfSuit = cal.discardedOfSuit;
        var dealMove = false;
        
        if (theMoveToDo instanceof MoveToEmptyColumn)
        {
            var emptyColumnMove = theMoveToDo as MoveToEmptyColumn;
            var fromColumn = emptyColumnMove.fromColumn;
            var positionOfTopCardInFromColumn = newColumns[fromColumn].length - 1;

            newColumns[emptyColumnMove.toColumn].push(newColumns[fromColumn][positionOfTopCardInFromColumn]);
            newColumns[fromColumn].pop();                
        }
        else if (theMoveToDo instanceof DiscardTopCardOfColumnMove)
        {
            var discardMove = theMoveToDo as DiscardTopCardOfColumnMove;
            var column = discardMove.columnToRemoveTopCardFrom;
            var cardDiscarded = newColumns[column][newColumns[column].length - 1];
            newColumns[column].pop();
            newDiscardedOfSuit[Card.getSuit(cardDiscarded)].push(cardDiscarded);                
        }
        else if (newStockPile !== null)
        {
            var cardsToPutOnTopOfColumns = newStockPile[0];
            for (var i = 0; i < newColumns.length; i++)
            {
                newColumns[i].push(cardsToPutOnTopOfColumns[i]);
            }
            newStockPile.splice(0, 1);
            dealMove = true;
        }

        let newSolutionSet = new IdiotSolutionSet({columns: newColumns, stockPile: newStockPile || undefined});
        let newIdiotLayout = new IdiotLayout({columns: newColumns, stockPile: newStockPile, discardedOfSuit: newDiscardedOfSuit, solutionSet: newSolutionSet});
        return new IdiotMoveConsequence(newIdiotLayout, dealMove);
    }
    blockMethods: IdiotBlockMethods = new IdiotBlockMethods();

    willNextMoveMakeGameImpossibleToWin(newMove: IdiotMoveConsequence) : boolean
    {
        return !this.blockMethods.isGamePossibleToWinFromOutset(newMove.step, false).result;
    }
    
    investigatePossibleMoves(layout: IdiotLayout): SolitaireMove<IdiotSolitaire>[] {
        var result : SolitaireMove<IdiotSolitaire>[] = [];

        for (var i = 0; i < layout.columns.length; i++)
        {
            var currentColumn = layout.columns[i];
            if (currentColumn.length > 0)
            {
                var topCard = currentColumn[currentColumn.length - 1];
                if (Card.isAce(topCard)) continue; // Aces cannot be discarded
                var addDiscardTopCardMove = false;
                for (var j = 0; j < layout.columns.length; j++)
                {
                    if (j !== i && layout.columns[j].length > 0)
                    {
                        var topCardOfDifferentColumn = layout.columns[j][layout.columns[j].length - 1];
                        if (Card.getSuit(topCard) === Card.getSuit(topCardOfDifferentColumn) &&
                            (Card.isAce(topCardOfDifferentColumn) || topCardOfDifferentColumn < topCard))
                        {
                            addDiscardTopCardMove = true; break;
                        }
                    }
                }
                if (addDiscardTopCardMove)
                {
                    result.push(new DiscardTopCardOfColumnMove(i));
                }
            }
            else
            {
                for (var l = 0; l < layout.columns.length; l++)
                {
                    if (layout.columns[l].length > 0)
                    {
                        result.push(new MoveToEmptyColumn(
                        {
                            toColumn: i,
                            fromColumn: l,
                            isTrivialMove: layout.stockPile !== null && layout.stockPile.length === 0 && layout.columns[l].length === 1
                        }));
                    }
                }
            }
        }

        if (layout.stockPile !== null && layout.stockPile.length > 0)
        {
            result.push(new DealMove());
        };

        return result;
    }
}
