import { ISolitaire } from '../Models/solitaire'
import { ISolitaireLayout } from './solitaireLayout'

export class MoveConsequence<TSolitaire extends ISolitaire,
    TLayout extends ISolitaireLayout<TSolitaire>>
{
    step: TLayout;
    constructor(step: TLayout) {
        this.step = step;
    }
}