import { Outcome } from "../outcome";
import { IVerificationModel } from "../verificationModel";
import { ScorpionSimulationModel } from "./scorpionSimulationModel";
import { ScorpionSolitaire } from "./scorpionSolitaire";

export class ScorpionVerificationModel extends ScorpionSimulationModel
implements IVerificationModel<ScorpionSolitaire> {
    outcome: Outcome;
    constructor(content: object[], outcome: string) {
        super(content);
        this.outcome = parseInt(outcome);
    }
    equals(other: ScorpionVerificationModel): boolean {
        return this.columns.every((column, coIndex) => column.every((card, caIndex) => card === other.columns[coIndex][caIndex]))
        && this.leftovers.every((card, caIndex) => card === other.leftovers[caIndex])
        && this.outcome === other.outcome;
    }
}