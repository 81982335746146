import React from "react";
import { ReactNode } from "react";
import { Col, Row, Card } from 'react-bootstrap';
import { Helper } from "../helper";
import { PlayResult, VerificationResult } from "../Models/playResult";
import { RunLog } from "../Models/runLog";
import { ICanBeVerified, ISolitaire } from "../Models/solitaire";
import { ISolitaireStateWithStatistics, ISolitaireStateWithVerifications, Verifications } from "../Models/solitaireState";
import { SolitaireTypeOf } from "../Models/solitaireType";
import { IVerificationModel } from "../Models/verificationModel";

export interface IVerificationViewProps<TSolitaire extends ISolitaire,
    TSolitaireThatCanBeVerified extends TSolitaire & ICanBeVerified<TSolitaire, TVerificationModel, TVerificationResult, TRunLog>,
TVerificationModel extends IVerificationModel<TSolitaire>,
TVerificationResult extends VerificationResult<TSolitaire, TRunLog>,
TRunLog extends RunLog,
TSolitaireState extends ISolitaireStateWithVerifications<TSolitaire>>
{
    solitaireType: SolitaireTypeOf<TSolitaire>;
    solitaire: TSolitaireThatCanBeVerified;
    solitaireState: TSolitaireState;
    steps: number;
    numberOfGames: number;
    enableFunctionality: boolean;
    runWrapper: (runMethod: () => void) => void;
    collectResults: (solitaireType: SolitaireTypeOf<TSolitaire>, solitaireState: ISolitaireStateWithStatistics<TSolitaire>, result: PlayResult<TSolitaire>[]) => void;
    changeVerifications: (solitaireType: SolitaireTypeOf<TSolitaire>, solitaireState: TSolitaireState, newVerifications: Verifications) => void;
}

export class VerificationView<TSolitaire extends ISolitaire,
TSolitaireThatCanBeVerified extends TSolitaire & ICanBeVerified<TSolitaire, TVerificationModel, TVerificationResult, TRunLog>,
TVerificationModel extends IVerificationModel<TSolitaire>,
TVerificationResult extends VerificationResult<TSolitaire, TRunLog>,
TRunLog extends RunLog,
TSolitaireState extends ISolitaireStateWithVerifications<TSolitaire>> extends React.Component<IVerificationViewProps<TSolitaire, TSolitaireThatCanBeVerified, TVerificationModel, TVerificationResult,TRunLog, TSolitaireState>> {
    constructor(props: IVerificationViewProps<TSolitaire, TSolitaireThatCanBeVerified, TVerificationModel, TVerificationResult, TRunLog, TSolitaireState>) {
        super(props);
        this._onResultsToVerifyChange = this._onResultsToVerifyChange.bind(this);
        this.verify = this.verify.bind(this);
    }
    
    _onResultsToVerifyChange(e: React.FormEvent<HTMLTextAreaElement>) {
        let newVerifications : Verifications
            = Object.assign({}, this.props.solitaireState.verifications, {resultsToVerifyAsJson: e.currentTarget.value});
        this.props.changeVerifications(this.props.solitaireType, this.props.solitaireState, newVerifications);
    }

    verifyResults = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        if (this.props.enableFunctionality) {
            this.props.runWrapper(this.verify);
        } else {            
            alert('You do not have permission to perform this operation.');
        }
    }

    verify() {
        let verifications = this.props.solitaireState.verifications;
        let solitaire = this.props.solitaire;
        try {
            var verificationModels = solitaire.cleanUpDuplicates(solitaire.convertJsonToVerificationModels(verifications.resultsToVerifyAsJson));
        } catch (e) {
            throw new Error("Could not parse input as verification models");
        }

        let result: TVerificationResult[] = [];
        let numberOfNewGames = this.props.numberOfGames > verificationModels.length ? verificationModels.length : this.props.numberOfGames;
        let amountOfVerificationsSucceeded = verifications.amountOfVerificationsSucceeded;
        let amountOfVerificationsNotSucceeded = verifications.amountOfVerificationsNotSucceeded;
        let unsuccessfulVerifications : TVerificationModel[] = [];
        let solitaireStateCopy = this.props.solitaireState;
        if (solitaire.canBePlayed<TSolitaire>()) {
            for (let i = 0; i < numberOfNewGames; i++) {
                Helper.log("===== Verification runthrough " + (i + 1) + " of " + numberOfNewGames);
                var verificationModel = verificationModels.shift();
                if (verificationModel === undefined) {
                    break;
                }
                if (!solitaire.testVerification(verificationModel)) {            
                    throw new Error("Model is invalid");
                }
                var verificationResult = solitaire.verify(this.props.steps, solitaireStateCopy, verificationModel);
                if (verificationResult.outcome === verificationModel.outcome) {
                    amountOfVerificationsSucceeded++;
                    result.push(verificationResult);
                    solitaireStateCopy = Object.assign(solitaireStateCopy, verificationResult.statistics);
                } else {
                    amountOfVerificationsNotSucceeded++;
                    unsuccessfulVerifications.push(verificationModel);
                }

            }
        }
        
        var unsuccessfulVerificationsAsJson = JSON.stringify(solitaire.convertVerificationModelsToJsonModels(unsuccessfulVerifications));
        var newResultsToVerifyAsJson = JSON.stringify(solitaire.convertVerificationModelsToJsonModels(verificationModels));

        this.props.collectResults(this.props.solitaireType, solitaireStateCopy, result);
        let newVerifications : Verifications = {
            verificationsNotSucceededAsJson: unsuccessfulVerificationsAsJson,
            amountOfVerificationsSucceeded: amountOfVerificationsSucceeded,
            amountOfVerificationsNotSucceeded: amountOfVerificationsNotSucceeded,
            resultsToVerifyAsJson: newResultsToVerifyAsJson
        }
        this.props.changeVerifications(this.props.solitaireType, solitaireStateCopy, newVerifications);
    }

    render() : ReactNode {
        const {solitaireState} = this.props;
        let verifications = solitaireState.verifications;
        let isValidJson = Helper.isJsonString(verifications.resultsToVerifyAsJson);
        return <Card className="text-center">
        <form onSubmit={this.verifyResults}>
            <Card.Body>                
            <Card.Title><h4>Verify</h4></Card.Title>
                <Row>
                    <Col sm className="text-right form-group">Results to verify as JSON:
                        {!isValidJson &&
                            <p style={{color: "red", fontWeight: "bold"}}>Invalid JSON</p>
                        }
                    </Col>
                    <Col sm className="text-left form-group">
                        <textarea
                            placeholder=""
                            className="form-control"
                            onChange={this._onResultsToVerifyChange}
                            value={verifications.resultsToVerifyAsJson}
                            rows={10}></textarea>
                    </Col>
                </Row>
                <Row>
                    <Col sm className="text-right form-group">
                        <span>Amount of successful verifications:</span>
                    </Col>
                    <Col sm className="text-left form-group">
                        <b>{verifications.amountOfVerificationsSucceeded}</b>
                    </Col>
                </Row>
            </Card.Body>
            <Row>
                <Col sm className="text-right form-group">
                    Results that were not verified:
                    </Col>
                    <Col sm className="text-left form-group">
                        <textarea
                            readOnly
                            placeholder=""
                            className="form-control"
                            value={verifications.verificationsNotSucceededAsJson}
                            rows={6}></textarea>
                    </Col>
                </Row>
            <Row style={{ alignItems: 'center' }}>
                <Col sm></Col>
                <Col sm className="text-left form-group">
                    <input type="submit" className="btn btn-primary" value="Verify" />
                </Col>
            </Row>
        </form>
    </Card>
    }
}