import { PlayResult, PlayResultWithWinningHistory, VerificationResult } from "./playResult";
import { RunLog } from "./runLog";
import { RunService } from "../runService";
import { State } from "./state";
import { SolitaireLayoutOverview } from "./solitaireLayoutOverview";
import { SolitaireLayoutInternal, ISolitaireLayoutOutput, IBaseSolitaireLayout } from "./baseSolitaireLayout";
import { MoveMethods } from "./moveMethods";
import { MoveConsequence } from "./moveConsequence";
import { ResultBody } from "./resultBody";
import { ISolitaireLayout } from "./solitaireLayout";
import { ISolutionSet } from "./solutionSet";
import { ISolitaireState } from "./solitaireState";
import { IStatisticsResponse } from "./statisticsResponse";
import { IVerificationModel } from "./verificationModel";
import { ISimulationModel } from "./simulationModel";
import { IBlockMethods } from "./blockMethods";

export interface ISolitaire
{
    checkAllPreviousSolutionStrings: boolean;
    saveSolutionStringsForUnwinnableLayouts: boolean;
    name: string;
    defaultNumberOfGames: number;
    maxHistoryLength: number;
    canBeSaved: <TSolitaire extends ISolitaire>() => this is IStatisticsCanBeSaved<TSolitaire>;
    statisticsCanBeRetrieved: <TStatisticsResponse extends IStatisticsResponse<TSolitaire>, TSolitaire extends ISolitaire>() => this is IStatisticsCanBeRetrieved<TStatisticsResponse, TSolitaire>;
    canBePlayed: <TSolitaire extends ISolitaire>() => this is Solitaire<TSolitaire>;
    canBeVerified: <TSolitaire extends ISolitaire,
    TVerificationModel extends IVerificationModel<TSolitaire>,
    TVerificationResult extends VerificationResult<TSolitaire, TRunLog>,
    TRunLog extends RunLog>() =>
        this is SolitaireThatCanBeVerified<TSolitaire, TVerificationModel, TVerificationResult, TRunLog>;
    canBeSimulated: <TSolitaire extends ISolitaire,
        TLayout extends ISolitaireLayout<TSolitaire>,
        TOutputLayout extends ISolitaireLayoutOutput<TSolitaire>,
        TSimulationModel extends ISimulationModel<TSolitaire>>() =>
        this is ICanBeSimulated<TSolitaire, TLayout, TOutputLayout, TSimulationModel>;
}

export interface IStatisticsCanBeSaved<TSolitaire extends ISolitaire>
{
    savePlayResultApiUrl: string;
    saveFilter(playResult: PlayResult<TSolitaire>): boolean;
}

export abstract class Solitaire<T extends ISolitaire>
{
    canBeSaved() : this is IStatisticsCanBeSaved<T> {
        return 'savePlayResultApiUrl' in this;
    }
    canBePlayed<T extends ISolitaire>() : this is Solitaire<T> {
        return 'play' in this;
    }
    statisticsCanBeRetrieved
        <TStatisticsResponse extends IStatisticsResponse<TSolitaire>,
        TSolitaire extends ISolitaire>() : this is IStatisticsCanBeRetrieved<TStatisticsResponse, TSolitaire> {
        return 'getPlayResultApiUrl' in this;
    }
    canBeVerified<TSolitaire extends ISolitaire,
    TVerificationModel extends IVerificationModel<TSolitaire>,
    TVerificationResult extends VerificationResult<TSolitaire, TRunLog>,
    TRunLog extends RunLog>() : this is SolitaireThatCanBeVerified<TSolitaire, TVerificationModel, TVerificationResult, TRunLog> {
        return 'verify' in this && 'convertJsonToVerificationModels' in this && 'convertVerificationModelToJsonModel' in this;
    }
    canBeSimulated<TSolitaire extends ISolitaire, TLayout extends ISolitaireLayout<TSolitaire>, TOutputLayout extends ISolitaireLayoutOutput<TSolitaire>, TSimulationModel extends ISimulationModel<TSolitaire>>() :
    this is ICanBeSimulated<TSolitaire, TLayout, TOutputLayout, TSimulationModel> {
        return 'simulate' in this;
    }

    getRunService<TLayoutOverview extends SolitaireLayoutOverview<T, TLayout, TSolutionSet>,    
    TLayout extends SolitaireLayoutInternal<T, TSolutionSet> & ISolitaireLayout<T> & TBaseLayout,
    TBaseLayout extends IBaseSolitaireLayout<T>,
    TMoveMethods extends MoveMethods<T, TMoveConsequence, TLayout, TSolutionSet, TResultBody, TRunLog>,
    TBlockMethods extends IBlockMethods<T, TLayout, TResultBody>,
    TMoveConsequence extends MoveConsequence<T, TLayout>,
    TSolutionSet extends ISolutionSet<T>,
    TResultBody extends ResultBody<T>,
    TRunLog extends RunLog>(): RunService<T, TRunLog, TLayoutOverview, TLayout, TMoveMethods, TBlockMethods, TMoveConsequence, TSolutionSet, TResultBody> {
        return new RunService<T, TRunLog, TLayoutOverview, TLayout, TMoveMethods, TBlockMethods, TMoveConsequence, TSolutionSet, TResultBody>();
    }
    abstract play(steps: number, state: Readonly<ISolitaireState<T>>): PlayResult<T>;
    abstract prepareSolitaireState(state: Readonly<State>): ISolitaireState<T>;
}

export interface IStatisticsCanBeRetrieved<TStatisticsResponse extends IStatisticsResponse<TSolitaire>, TSolitaire extends ISolitaire> {
    
    getPlayResultApiUrl(version: string): string;
    convertStatisticsApiResponseToStateStats(json: TStatisticsResponse, oldState: ISolitaireState<TSolitaire>): ISolitaireState<TSolitaire>;
}

export interface ICanBeVerified<TSolitaire extends ISolitaire,
TVerificationModel extends IVerificationModel<TSolitaire>,
TVerificationResult extends VerificationResult<TSolitaire, TRunLog>,
TRunLog extends RunLog> {
    convertJsonToVerificationModels(json: string) : Array<TVerificationModel>;
    convertVerificationModelToJsonModel(model: TVerificationModel): any;
    verify(steps: number, state: Readonly<ISolitaireState<TSolitaire>>, verificationModel: TVerificationModel) : TVerificationResult;
    convertVerificationModelsToJsonModels(models: TVerificationModel[]): any[];
    testVerification(model: TVerificationModel) : boolean;
    cleanUpDuplicates(models: TVerificationModel[]) : TVerificationModel[];
}

export interface ICanBeSimulated<TSolitaire extends ISolitaire, TLayout extends ISolitaireLayout<TSolitaire>, TOutputLayout extends ISolitaireLayoutOutput<TSolitaire>, TSimulationModel extends ISimulationModel<TSolitaire>> {
    simulate(steps: number, state: Readonly<ISolitaireState<TSolitaire>>, simulationModel: TSimulationModel) : PlayResultWithWinningHistory<TSolitaire, TOutputLayout>;
    convertJsonToSimulationModel(json: string): TSimulationModel;
    convertOutputToSimulationOutput(output: TLayout) : TOutputLayout;
}


export abstract class SolitaireThatCanBeVerified<TSolitaire extends ISolitaire,
    TVerificationModel extends IVerificationModel<TSolitaire>,
    TVerificationResult extends VerificationResult<TSolitaire, TRunLog>,
    TRunLog extends RunLog>
    extends Solitaire<TSolitaire> 
    implements ICanBeVerified<TSolitaire, TVerificationModel, TVerificationResult, TRunLog> {
    abstract convertJsonToVerificationModels(json: string): TVerificationModel[];
    abstract convertVerificationModelToJsonModel(model: TVerificationModel): any;
    convertVerificationModelsToJsonModels(models: TVerificationModel[]): any[] {
        return models.map(model => this.convertVerificationModelToJsonModel(model));
    }
    abstract verify(steps: number, state: Readonly<ISolitaireState<TSolitaire>>, verificationModel: TVerificationModel): TVerificationResult;
    testVerification(model: TVerificationModel) : boolean {
        let json = "[" + JSON.stringify(this.convertVerificationModelToJsonModel(model)) + "]";
        return model.equals(this.convertJsonToVerificationModels(json)[0]);
    }

    cleanUpDuplicates(models: TVerificationModel[]) : TVerificationModel[] {
        var a : TVerificationModel[] = [];
        var lth = models.length;
        while (lth > 0) {
            var item = models[lth - 1];
            var p = false;
            for (var j = 0; j < lth - 1; j++) {
                if (item.equals(models[j])) {
                    p = true;
                    break;
                }
            }
            if (!p) { a.push(item); }
            lth--;
        }       
        return a;
    }
}

export abstract class SolitaireThatCanBeVerifiedAndSimulated<TSolitaire extends ISolitaire,
    TLayout extends ISolitaireLayout<TSolitaire>,
    TOutputLayout extends ISolitaireLayoutOutput<TSolitaire>,
    TVerificationModel extends IVerificationModel<TSolitaire>,
    TSimulationModel extends ISimulationModel<TSolitaire>,
    TVerificationResult extends VerificationResult<TSolitaire, TRunLog>,
    TRunLog extends RunLog> 
extends SolitaireThatCanBeVerified<TSolitaire, TVerificationModel, TVerificationResult, TRunLog>
implements ICanBeSimulated<TSolitaire, TLayout, TOutputLayout, TSimulationModel>
{
    abstract convertOutputToSimulationOutput(output: TLayout): TOutputLayout;
    abstract simulate(steps: number, state: Readonly<ISolitaireState<TSolitaire>>, simulationModel: TSimulationModel): PlayResultWithWinningHistory<TSolitaire, TOutputLayout>;
    abstract convertJsonToSimulationModel(json: string): TSimulationModel;
}