import { ISolitaire } from "./solitaire";
import { SolitaireMove } from "./solitaireMove";

export class MoveObject<TSolitaire extends ISolitaire>
{
    index: number;
    theNextMove: SolitaireMove<TSolitaire>;

    constructor(index: number, move: SolitaireMove<TSolitaire>)
    {
        this.index = index;
        this.theNextMove = move;
    }
}