import { Card } from "../../card";
import { IBlockMethods } from "../blockMethods";
import { IdiotLayout } from "./idiotLayout";
import { IdiotResultBody } from "./idiotResultBody";
import { IdiotSolitaire } from "./idiotSolitaire";

export class IdiotBlockMethods implements IBlockMethods<IdiotSolitaire, IdiotLayout, IdiotResultBody> {

    private calculateLargestNonDiscardedNonAceCardsOfSuit(layout: IdiotLayout, suit: number): void {
        let largestNonDiscardedCardOfSameSuit = suit * 13 + 1; // King
        for (var m = 0; m < layout.discardedOfSuit[suit].length; m++) {
            if (layout.discardedOfSuit[suit].indexOf(largestNonDiscardedCardOfSameSuit) > -1) {
                largestNonDiscardedCardOfSameSuit++;
            }
            else {
                break;
            }
        }
        layout.largestNonDiscardedNonAceCardsOfSuit[suit] = Card.isAce(largestNonDiscardedCardOfSameSuit) ? null : largestNonDiscardedCardOfSameSuit;
    }

    isGamePossibleToWinFromOutset(layout: IdiotLayout, withLogging: boolean): IdiotResultBody {
        // If the four aces are in four different columns, the presence of some card below any of the aces
        // will make the game impossible to win, seeing as it cannot be discarded due to the ace not being moveable
        // unless it's to an empty column (which the other aces block).

        // var cardBelowAceCannotBeMoved = false;

        // Suppose now that the four aces are at the bottom of four different columns.
        // There are scenarios in which the largest non-discarded cards of the four suits
        // may never be discarded - suppose for instance that the first column goes He1, SpK, the second goes Sp1, HeK and
        // the third and fourth consist only of the two other aces. The SpK cannot be discarded since no column can ever be empty
        // from this point onward (so that it is not possible to move HeK).
        // var blockOfLargestNonDiscardedCardAboveAces = false;

        var oneAceInEachColumn = true;
        var indexOfFirstAce : {[key:number]: number} = {};
        var columnOfLargestNonDiscardedCardAboveAceWithSameSuit : {[key:number]: number} = {};
        var indexOfLargestNonDiscardedCardAboveAceWithSameSuit : {[key:number]: number} = {};
        var columnOfAceBySuit : {[key:number]: number} = {};
        var numberOfAces = 0;

        for (var i = 0; i < layout.columns.length; i++)
        {
            var columnHasOneAce = false;
            var column = layout.columns[i];
            for (var j = 0; j < column.length; j++)
            {
                if (Card.isAce(column[j]))
                {
                    numberOfAces++;
                    var suit = Card.getSuit(column[j]);
                    this.calculateLargestNonDiscardedNonAceCardsOfSuit(layout, suit);
                    columnOfAceBySuit[suit] = i;

                    if (columnHasOneAce)
                    {
                        columnHasOneAce = false;
                        break;
                    }
                    else
                    {
                        columnHasOneAce = true;
                        indexOfFirstAce[i] = j;

                        for (var n = 0; n < layout.columns.length; n++)
                        {
                            for (var k = 0; k < layout.columns[n].length; k++)
                            {
                                var largestNonDiscardedNonAceCardsOfThisSuit = layout.largestNonDiscardedNonAceCardsOfSuit[suit];
                                if (largestNonDiscardedNonAceCardsOfThisSuit !== null && layout.columns[n][k] === largestNonDiscardedNonAceCardsOfThisSuit)
                                {
                                    columnOfLargestNonDiscardedCardAboveAceWithSameSuit[suit] = n;
                                    indexOfLargestNonDiscardedCardAboveAceWithSameSuit[suit] = k;
                                }
                            }
                        }
                    }                        
                }
            }
            if (!columnHasOneAce)
            {
                oneAceInEachColumn = false;
            }
        }

        if (numberOfAces === 4)
        {
            if (oneAceInEachColumn) {
                for (var s = 0; s < Object.keys(indexOfFirstAce).length; s++)
                {
                    if (indexOfFirstAce[s] > 0)
                    {
                        return new IdiotResultBody(false);
                    }
                }

                // All aces are at the bottom of the columns here
                let distinct = Array.from(new Set(Object.values(columnOfLargestNonDiscardedCardAboveAceWithSameSuit)));
                let values = Object.values(columnOfLargestNonDiscardedCardAboveAceWithSameSuit);
                if (distinct.length === values.length)
                {
                    var columnsOfTheAces : number[] = [];
                    for (var t = 0; t < Object.keys(columnOfLargestNonDiscardedCardAboveAceWithSameSuit).length; t++) {
                        columnsOfTheAces.push(columnOfAceBySuit[+Object.keys(columnOfLargestNonDiscardedCardAboveAceWithSameSuit)[t]]);
                    }
                    var doesNotOverlap = false;
                    for (var l = 0; l < values.length; l++) {
                        if (columnsOfTheAces.indexOf(values[l]) === -1)
                        {
                            doesNotOverlap = true;
                            break;
                        }
                    }
                    if (!doesNotOverlap)
                    {
                        return new IdiotResultBody(false);
                    }
                }
            }
            else
            {
                var suitsOfAcesByColumn : {[key: number]: number[]} = {};
                for (var q = 0; q < Object.keys(columnOfAceBySuit).length; q++)
                {
                    if (suitsOfAcesByColumn[columnOfAceBySuit[q]] === undefined)
                    {
                        suitsOfAcesByColumn[columnOfAceBySuit[q]] = [q];
                    }
                    else
                    {
                        suitsOfAcesByColumn[columnOfAceBySuit[q]].push(q);
                    }
                }

                // If the columns go:
                // HeA DiA SpA SpK
                //         ClA
                // the king of spades cannot ever be discarded. If there are only three columns with aces,
                // let S be the suit of the bottom-most ace in the column with two aces.
                // We then check that the largest non-discarded card of suit S does not block the column with no aces.
                // (Only way to do so would be to move the top ace.)
                if (Object.keys(suitsOfAcesByColumn).length === 3)
                {
                    var columnWithoutAcesAsList = [0,1,2,3];
                    var columnWithTwoAces = 0;
                    for (var r = 0; r < Object.keys(suitsOfAcesByColumn).length; r++) {
                        let key = +Object.keys(suitsOfAcesByColumn)[r];
                        let index = columnWithoutAcesAsList.indexOf(key);
                        columnWithoutAcesAsList.splice(index, 1);
                        if (suitsOfAcesByColumn[key].length === 2) columnWithTwoAces = key;
                    }
                    var columnWithoutAces = columnWithoutAcesAsList[0];
                    var suitOfFirstAceInColumnWithTwoAces = 0;
                    for (var x = 0; x < layout.columns[columnWithTwoAces].length; x++)
                    {
                        let card = layout.columns[columnWithTwoAces][x];
                        if (Card.isAce(card))
                        {
                            suitOfFirstAceInColumnWithTwoAces = Card.getSuit(card);
                            break;
                        }
                    }
                    var m = layout.largestNonDiscardedNonAceCardsOfSuit[suitOfFirstAceInColumnWithTwoAces];
                    if (m !== null && layout.columns[columnWithoutAces].indexOf(m) > -1)
                    {
                        return new IdiotResultBody(false);
                    }
                }
            }
        }

        return new IdiotResultBody(true);
    }
}