import { ISolitaireLayoutOutput } from "./Models/baseSolitaireLayout";
import { Dictionary } from "./Models/dictionary";
import { IdiotSolitaire } from "./Models/Idiot/idiotSolitaire";
import { VerificationResult } from "./Models/playResult";
import { RunLog } from "./Models/runLog";
import { ScorpionColumnsAndLeftovers } from "./Models/Scorpion/scorpionColumnsAndLeftovers";
import { ScorpionSolitaire } from "./Models/Scorpion/scorpionSolitaire";
import { ISimulationModel } from "./Models/simulationModel";
import { ICanBeSimulated, ICanBeVerified, ISolitaire } from "./Models/solitaire";
import { ISolitaireLayout } from "./Models/solitaireLayout";
import { IBaseSolitaireState, ISolitaireState, ISolitaireStateWithSimulations, ISolitaireStateWithStatistics, SolitaireState, SolitaireStateWithVerifications, SolitaireStateWithVerificationsAndSimulations } from "./Models/solitaireState";
import { SolitaireTypeOf } from "./Models/solitaireType";
import { IVerificationModel } from "./Models/verificationModel";
import { Newable } from "./newable";

export class StateConnector<TSolitaire extends ISolitaire, TSolitaireState extends ISolitaireState<TSolitaire>> implements BaseStateConnector {
    state: Newable<TSolitaireState>;
    solitaire: SolitaireTypeOf<TSolitaire>;
    constructor(solitaire: SolitaireTypeOf<TSolitaire>, state: Newable<TSolitaireState>) {
        this.solitaire = solitaire;
        this.state = state;
    }
    
    isDefined<TSolitaire extends ISolitaire, TSolitaireState extends ISolitaireState<TSolitaire>>() :
        this is StateConnector<TSolitaire, TSolitaireState> {
        return 'solitaire' in this && 'state' in this;
    }
    hasStatistics<TSolitaire extends ISolitaire, TSolitaireState extends ISolitaireStateWithStatistics<TSolitaire>>() :
        this is IStateConnectorWithStatistics<TSolitaire, TSolitaireState> {
        return this.isDefined();
    }
    hasStatisticsAndCanBeVerified<TSolitaire extends ISolitaire & ICanBeVerified<TSolitaire, TVerificationModel, TVerificationResult, TRunLog>,
    TVerificationModel extends IVerificationModel<TSolitaire>,
    TVerificationResult extends VerificationResult<TSolitaire, TRunLog>,
    TRunLog extends RunLog,
        TSolitaireState extends SolitaireStateWithVerifications<TSolitaire>>():
    this is StateConnector<TSolitaire, TSolitaireState> {
        return this.isDefined();
    }
    // hasStatisticsAndCanBeSimulated<TSolitaire extends ISolitaire & Solita,
    // TLayout extends ISolitaireLayoutOutput<TSolitaire>,
    // TSolitaireState extends ISolitaireStateWithSimulations<TSolitaire, TLayout>,>() : this is IStateConnectorWithSimulations<TSolitaire, TLayout, TSolitaireState> {
    //     return this.isDefined();
    // }
    hasStatisticsAndCanBeSimulated<TSolitaire extends ISolitaire & ICanBeSimulated<TSolitaire, TLayout, TOutputLayout, TSimulationModel>,
    TLayout extends ISolitaireLayout<TSolitaire>, TOutputLayout extends ISolitaireLayoutOutput<TSolitaire>,
    TSimulationModel extends ISimulationModel<TSolitaire>,
    TSolitaireState extends SolitaireStateWithVerificationsAndSimulations<TSolitaire, TOutputLayout>>() :
    this is IStateConnectorWithSimulations<TSolitaire, TOutputLayout, TSolitaireState> {
        return this.isDefined();
    }
    getFromStates(states: Dictionary<Newable<ISolitaire>, IBaseSolitaireState>) : TSolitaireState | undefined {
        var state = states.get(this.solitaire);
        return state !== undefined ? new this.state(state as TSolitaireState) : undefined;
    }
}

export interface IStateConnectorWithStatistics<TSolitaire extends ISolitaire,
    TSolitaireState extends ISolitaireStateWithStatistics<TSolitaire>> extends StateConnector<TSolitaire, TSolitaireState> {
}

export interface IStateConnectorWithVerifications<TSolitaire extends ISolitaire,
    TSolitaireState extends SolitaireStateWithVerifications<TSolitaire>> extends StateConnector<TSolitaire, TSolitaireState> {
}

export interface IStateConnectorWithSimulations<TSolitaire extends ISolitaire,
    TLayout extends ISolitaireLayoutOutput<TSolitaire>,
    TSolitaireState extends ISolitaireStateWithSimulations<TSolitaire, TLayout>> extends StateConnector<TSolitaire, TSolitaireState> {
}

export interface IStateConnectorWithSimulations<TSolitaire extends ISolitaire,
    TLayout extends ISolitaireLayoutOutput<TSolitaire>,
    TSolitaireState extends ISolitaireStateWithSimulations<TSolitaire, TLayout>> extends StateConnector<TSolitaire, TSolitaireState> {
}

export interface BaseStateConnector {
    isDefined: <TSolitaire extends ISolitaire, TSolitaireState extends ISolitaireState<TSolitaire>>()
        => this is StateConnector<TSolitaire, TSolitaireState>;
}

class ScorpionState extends SolitaireStateWithVerificationsAndSimulations<ScorpionSolitaire, ScorpionColumnsAndLeftovers> {}
class IdiotState extends SolitaireState<IdiotSolitaire> {}

export const stateConnectors : BaseStateConnector[] = 
[
    new StateConnector(ScorpionSolitaire, ScorpionState),
    new StateConnector(IdiotSolitaire, IdiotState)
]