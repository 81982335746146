import { ISolitaire } from "./solitaire";
import { ISolitaireLayout } from "./solitaireLayout";
import { SolitaireLayoutOverview } from "./solitaireLayoutOverview";
import { ISolutionSet } from "./solutionSet";
import { SolitaireLayoutInternal } from "./baseSolitaireLayout";
import { FailureHistory } from "./failureHistory";

export class HistoryMethods<TSolitaire extends ISolitaire,
    TLayout extends SolitaireLayoutInternal<TSolitaire, TSolutionSet> & ISolitaireLayout<TSolitaire>,
    TSolutionSet extends ISolutionSet<TSolitaire>> {

    addToFailureHistory<TLayoutOverview extends SolitaireLayoutOverview<TSolitaire, TLayout, TSolutionSet>>(failureHistory: FailureHistory, history: TLayoutOverview[], steps: number) {
        let l = history.length;
        let a = [];
        for (let i = 0; i < l; i++) {
            let movesTriedOut = history[i].movesTriedOut;
            if (i === l - 1 && movesTriedOut.length === 0) return failureHistory;
            let latestMoveTriedOut = movesTriedOut[movesTriedOut.length - 1];
            a.push(latestMoveTriedOut);
        }
        let nextLevel = failureHistory;
        for (let j = 0; j < a.length + 1; j++) {
            if (j < a.length) {
                nextLevel[a[j]] = nextLevel[a[j]] || {};
                nextLevel = nextLevel[a[j]];
            }
            else { nextLevel.at = steps; }
        }
        return failureHistory;
    }
    
    hasBeenSeenBeforeAfterSequenceOfTrivialMoves<TLayoutOverview extends SolitaireLayoutOverview<TSolitaire, TLayout, TSolutionSet>>(history: TLayoutOverview[], solutionString: string) : boolean
    {
        let solutionSetHistoryForLatestTrivialMoves = [];
        let latestNumberOfTrivialMovesPerformed = history[history.length - 1].trivialMovesPerformed;
        //if (latestNumberOfTrivialMovesPerformed === 0) return false;
        for (let i = 0; i < history.length; i++) {
            if (latestNumberOfTrivialMovesPerformed - i === history[history.length - 1 - i].trivialMovesPerformed) { // Always true for i = 0, so
                // solutionSetHistoryForLatestTrivialMoves will always contain one element
                solutionSetHistoryForLatestTrivialMoves.push((history[history.length - 1 - i].columnsAndLeftovers.solutionSet.translateToString()));
            } else {
                break;
            }
        }
        return solutionSetHistoryForLatestTrivialMoves.indexOf(solutionString) > -1;
    }
    
    hasBeenSeenBefore<TLayoutOverview extends SolitaireLayoutOverview<TSolitaire, TLayout, TSolutionSet>>(history: TLayoutOverview[], solutionString: string) : boolean
    {
        var maxStepsToBack = history.length < 24 ? history.length : 24;
        for (var i = 0; i < maxStepsToBack; i++)
        {
            if (history[history.length - 1 - i].columnsAndLeftovers.solutionSet.translateToString() === solutionString)
            {
                return true;
            }
        }
        return false;
    }
}