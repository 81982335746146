import { Newable } from "./newable";

// eslint-disable-next-line @typescript-eslint/no-namespace
export class Helper
{

    static log(text: string) : void
    {
        console.log(text);
    }
    
    static isJsonString(str: string) {
        try {
            JSON.parse(str);
        } catch (e) {
            return false;
        }
        return true;
    }

    
    static init<TParent, TChild extends TParent>(child: Newable<TChild>, content: Partial<TParent>) : TChild {
        return Object.assign(new child(), content);
    }
}

export interface ICloneable<T> {
    clone(): T;
}


