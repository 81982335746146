import * as React from 'react';
import ReactDOM from 'react-dom';
import { Alert, Button, Container, Col, Row, Card, Form, Tabs, Tab } from 'react-bootstrap';
import './index.css';

import { ISolitaire, IStatisticsCanBeRetrieved, IStatisticsCanBeSaved } from "./Models/solitaire";
import { PlayResult } from "./Models/playResult";
import { Newable } from "./newable";
import { State, PartialState } from "./Models/state";
import { Helper } from './helper';
import * as CookieHelper from './cookies';
import * as serviceWorker from './serviceWorker';
import { Statistics } from './statisticsMethods';
import { ReactNode } from 'react';
import * as StateHelper from './stateHelper';
import { activeSolitaires } from './activeSolitaires';
import { IBaseSolitaireState, ISolitaireStateWithSimulations, ISolitaireStateWithStatistics, ISolitaireStateWithVerifications, Simulations, Verifications } from './Models/solitaireState';
import { IStatisticsResponse } from './Models/statisticsResponse';
import { SolitaireType, SolitaireTypeOf } from './Models/solitaireType';
import { viewConnectors } from './Views/views';
import { IBaseSolitaireView } from './Views/solitaireView';
import { stateConnectors } from './stateConnector';
import { ScorpionSolitaire } from './Models/Scorpion/scorpionSolitaire';
import { ISolitaireLayoutOutput } from './Models/baseSolitaireLayout';
import { SimulationView } from './Components/simulationView';
import { VerificationView } from './Components/verificationView';
import { StatisticsView } from './Components/statisticsView';
import { UiWindow } from './Components/uiWindow';
import { LoginView } from './Components/loginView';

const initialState = new PartialState({
    currentSolitaire: ScorpionSolitaire,
    logsEnabled: true,
    saveResultsInDatabase: false,
    isAuthorized: false,
    simulationIsRunning: false,    
    selectedTab: UiWindow.ExtraStatistics
});

export interface IProps {}

export class Game extends React.Component<IProps, State> {

    constructor(props: IProps) {
        super(props);
        this.state = this.resetStuff();
        this.runThrough = this.runThrough.bind(this);
        this.reset = this.reset.bind(this);
        this.refreshStatistics = this.refreshStatistics.bind(this);

        this._onLogsEnabledChange = this._onLogsEnabledChange.bind(this);
        this._onSaveResultsInDatabaseChange = this._onSaveResultsInDatabaseChange.bind(this);

        this._onNumberOfGamesChange = this._onNumberOfGamesChange.bind(this);
        this._onCurrentSolitaireChange = this._onCurrentSolitaireChange.bind(this);

        /* Without binding this way, whenever a React component calls this method may think
           that the "this" expression refers to the Props class of the component, not the Game class */
        this.changeVerifications = this.changeVerifications.bind(this);
        this.changeSimulation = this.changeSimulation.bind(this);        
        this.onLoginSuccess = this.onLoginSuccess.bind(this);
        this.saveRuns = this.saveRuns.bind(this);
        this.collectResults = this.collectResults.bind(this);
        this.runWrapper = this.runWrapper.bind(this);
    }

    steps : number = 250000000;

    resetStuff(){
        let stateCopy = initialState.clone();
        let currentSolitaire = initialState.currentSolitaire;
        stateCopy.currentSolitaire = currentSolitaire;
        stateCopy.currentSolitaireView = this.getSolitaireView(currentSolitaire);   
        var newState = new State({...stateCopy,
            username: '', password: '', accessToken: CookieHelper.getCookie("accessToken") || undefined } as Partial<State>);
        return this.prepare(newState);
    }

    getPropertiesChangedOnSolitaireChange<TSolitaire extends ISolitaire>(solitaireType: Newable<TSolitaire>): Partial<PartialState> {
        let solitaire = new solitaireType();
        return { numberOfGames: solitaire.defaultNumberOfGames, currentSolitaireCanBeSaved: solitaire.canBeSaved(), 
            currentSolitaireCanBeVerified: solitaire.canBeVerified(), currentSolitaireCanBeSimulated: solitaire.canBeSimulated() };
    }

    prepare(state: Readonly<State>) : State {
        let currentSolitaire = state.currentSolitaire;
        let newState : Readonly<State> = Object.assign(state,
            { solitaireStates: StateHelper.prepareSolitaireStates(state) },
            this.getPropertiesChangedOnSolitaireChange(currentSolitaire));
        return newState;
    }

    reset() {
        this.setState(this.resetStuff());
    }

    checkIfAllowed() {
        if (!this.state.isAuthorized) {
            alert('You do not have permission to perform this change.');
            return false;
        }
        return true;
    }

    runThrough() {
        let solitaire = StateHelper.getCurrentSolitaire(this.state);
        this.run(solitaire);
    }

    run<TSolitaire extends ISolitaire>
    (solitaireType: SolitaireTypeOf<TSolitaire>) : void {
        let solitaireStateCopy = this.state.solitaireStates.get(solitaireType);
        if (solitaireStateCopy === undefined) {
            throw new Error("Solitaire type not defined");
        } else {
            this.runWrapper(() => {this.runSimulation(solitaireType)});            
        }
    }

    runWrapper(runMethod: () => void) : void {
        let that = this;
        this.setState({simulationIsRunning: true}, function() {
            runMethod();
            that.setState({simulationIsRunning: false});
        }); 
    }

    tryGetStateWithStatistics<TSolitaire extends ISolitaire>
        (solitaireType: SolitaireTypeOf<TSolitaire>) : ISolitaireStateWithStatistics<TSolitaire> | undefined {
        for (var connector of stateConnectors) {
            if (!connector.isDefined() || connector.solitaire.name !== solitaireType.name 
                || !connector.hasStatistics()) continue;

            return connector.getFromStates(this.state.solitaireStates);
        }
        return undefined;
    }

    tryGetStateWithStatisticsAndVerifications<TSolitaire extends ISolitaire>
        (solitaireType: SolitaireTypeOf<TSolitaire>) : ISolitaireStateWithVerifications<TSolitaire> | undefined {
        for (var connector of stateConnectors) {
            if (!connector.isDefined() || connector.solitaire.name !== solitaireType.name 
                || !connector.hasStatisticsAndCanBeVerified()) continue;
            return connector.getFromStates(this.state.solitaireStates);
        }
        return undefined;
    }

    tryGetStateWithStatisticsAndSimulations<TSolitaire extends ISolitaire>
        (solitaireType: SolitaireTypeOf<TSolitaire>) : ISolitaireStateWithSimulations<TSolitaire, ISolitaireLayoutOutput<TSolitaire>> | undefined {
        for (var connector of stateConnectors) {
            if (!connector.isDefined() || connector.solitaire.name !== solitaireType.name 
                || !connector.hasStatisticsAndCanBeSimulated()) continue;
            return connector.getFromStates(this.state.solitaireStates);
        }
        return undefined;
    }

    runSimulation<TSolitaire extends ISolitaire>
        (solitaireType: SolitaireTypeOf<TSolitaire>) {
        let solitaire = new solitaireType();
        // let solitaireStateCopy = this.state.solitaireStates.get(solitaireType);
        let solitaireStateCopy = this.tryGetStateWithStatistics(solitaireType);
        if (solitaireStateCopy === undefined) {            
            throw new Error("Solitaire state not defined");
        }

        // let total = solitaireStateCopy.total;
        let result: PlayResult<TSolitaire>[] = [];
        let numberOfNewGames = this.state.numberOfGames;
        if (solitaire.canBePlayed<TSolitaire>()) {
            for (let i = 0; i < numberOfNewGames; i++) {
                Helper.log("===== Runthrough " + (i + 1) + " of " + numberOfNewGames);
                var playResult = solitaire.play(this.steps, solitaireStateCopy);
                result.push(playResult);
                solitaireStateCopy = Object.assign(solitaireStateCopy, playResult.statistics);
            }
        }
        this.collectResults(solitaireType, solitaireStateCopy, result);
    }

    collectResults<TSolitaire extends ISolitaire>(solitaireType: SolitaireTypeOf<TSolitaire>, solitaireStateCopy: ISolitaireStateWithStatistics<TSolitaire>, result: PlayResult<TSolitaire>[]) {
        let solitaire = new solitaireType();
        let statistics = solitaireStateCopy.statistics;
        let topNumberOfStepsRequired = solitaireStateCopy.topNumberOfStepsRequired;
        let total = solitaireStateCopy.total;
        // let statistics = {...this.state.statistics} as StatisticsClass;
        statistics.percentageString = Statistics.calculateWinPercentage(statistics.won || 0, total);

        this.setState(prevState => ({ simulationIsRunning: false, 
            solitaireStates: StateHelper.updateSolitaireStates(prevState.solitaireStates,
                solitaireType, solitaireStateCopy, statistics, total, topNumberOfStepsRequired)}));
        if (this.state.saveResultsInDatabase && solitaire.canBeSaved()) {
            this.saveRuns(solitaire, result);
        }
    }
    
    async saveRuns
    <TSolitaire extends ISolitaire & IStatisticsCanBeSaved<TSolitaire>>(solitaire: TSolitaire, body: PlayResult<TSolitaire>[]) : Promise<void> {
        var requestBody = body.filter(solitaire.saveFilter);
        if (requestBody.length === body.length) {
            await fetch(solitaire.savePlayResultApiUrl, {//'https://scorpion.ananas.nu/api/run'
                method: 'POST',
                mode: 'cors',
                body: JSON.stringify(body),
                headers: this.getAuthorizationHeaders()
            });
        }
    }

    async refreshStatistics
        <TSolitaire extends ISolitaire & IStatisticsCanBeRetrieved<TStatisticsResponse, TSolitaire>,
        TStatisticsResponse extends IStatisticsResponse<TSolitaire>>
        (solitaireType: SolitaireType, solitaire: TSolitaire) : Promise<void> {
        if (!this.checkIfAllowed()) return;        
        if (!this.state.solitaireStates.has(solitaireType)) {
            throw new Error("State does not contain state of solitaire type " + solitaireType.name)
        }
        let tryGetState = this.tryGetStateWithStatistics(solitaireType);
        if (tryGetState === undefined) {
            throw new Error("Solitaire type does not contain statistics");
        } else {
            let state : ISolitaireStateWithStatistics<TSolitaire> = tryGetState;
            let version = state.version;
            fetch(solitaire.getPlayResultApiUrl(version), {
                method: 'GET',
                mode: 'cors',
                headers: this.getAuthorizationHeaders()
            }).then(async function (response) { if (response.ok) { return response.json(); } else { throw new Error(await response.json()) } })
                .then((json: TStatisticsResponse) => {
                    let newSolitaireState = solitaire.convertStatisticsApiResponseToStateStats(json, state);
                    this.setState(prevState => ({...prevState, 
                        solitaireStates: StateHelper.updateSolitaireStatesWithNewValue(prevState.solitaireStates, 
                            solitaireType, newSolitaireState)}));
                })
                .catch(() => {
                });
        }
    }

    onLoginSuccess() {
        let accessToken = CookieHelper.getCookie('accessToken');
        if (accessToken) {
            this.setState({ accessToken: accessToken, isAuthorized: true }, this.refreshStatisticsForCurrentSolitaire);
        }
    }

    getAuthorizationHeaders() {
        return CookieHelper.getCookie('accessToken') !== null ? {
            "Content-Type": "application/json; charset=UTF-8",
            "Access-Token": CookieHelper.getCookie('accessToken') || ""
        } : undefined
    }

    componentDidMount() {
        if (CookieHelper.getCookie('accessToken')) {
            fetch('https://scorpion.ananas.nu/api/verify', {
            method: 'GET',
            mode: 'cors',
            headers: this.getAuthorizationHeaders()
            })
            .then(async function (response) { 
                if (response.ok) { 
                    return response.json(); 
                }
                else { 
                    throw new Error(await response.json());
                }
            })
            .then((json) => { 
                this.setState({ isAuthorized: json.verified});                
                this.refreshStatisticsForCurrentSolitaire();
            });
        } else {
            this.setState({ isAuthorized: false });
        }
    }

    currentSolitaireIs<TSolitaire extends ISolitaire>(solitaire: Newable<TSolitaire>) : boolean {
        return StateHelper.currentSolitaireIs(this.state, solitaire);
    }

    refreshStatisticsForCurrentSolitaire() {
        let currentSolitaire = this.state.currentSolitaire;
        let solitaire = new currentSolitaire();
        if (solitaire.statisticsCanBeRetrieved()) {
            this.refreshStatistics(currentSolitaire, solitaire);
        }
    }

    _onLogsEnabledChange() {
        if (!this.checkIfAllowed()) return;
        this.setState({ logsEnabled: !this.state.logsEnabled });
    }

    _onSaveResultsInDatabaseChange() {
        if (!this.checkIfAllowed()) return;
        this.setState({ saveResultsInDatabase: !this.state.saveResultsInDatabase });
    }
    
    _onNumberOfGamesChange(e: React.FormEvent<HTMLInputElement>) {
        this.setState({ numberOfGames: +e.currentTarget.value });
    }
    
    _onChangeTab(e: React.FormEvent<HTMLInputElement>) {
        this.setState({ numberOfGames: +e.currentTarget.value });
    }

    _onCurrentSolitaireChange(e: React.FormEvent<HTMLSelectElement>) {
        var newSolitaireString = e.currentTarget.value;
        var newSolitaire = StateHelper.getCurrentSolitaireByString(newSolitaireString);
        var view = this.getSolitaireView(newSolitaire);
        var newProps = this.getPropertiesChangedOnSolitaireChange(newSolitaire);
        this.setState(prevState => ({...prevState, currentSolitaire: newSolitaire, currentSolitaireView: view, ...newProps}));
    }

    changeVerifications<TSolitaire extends ISolitaire, TSolitaireState extends ISolitaireStateWithVerifications<TSolitaire>>
        (solitaireType: SolitaireTypeOf<TSolitaire>, solitaireState: TSolitaireState, newVerifications: Verifications) {
        if (this.state.currentSolitaireCanBeVerified) {
            this.setState(prevState => ({...prevState,
            solitaireStates: StateHelper.changeVerifications(prevState.solitaireStates, solitaireType, solitaireState, newVerifications)}));
        }
    }

    changeSimulation<TSolitaire extends ISolitaire, TSolitaireState extends ISolitaireStateWithSimulations<TSolitaire, TLayout>, TLayout extends ISolitaireLayoutOutput<TSolitaire>>
        (solitaireType: SolitaireTypeOf<TSolitaire>, solitaireState: TSolitaireState, simulations: Simulations) {
        if (this.state.currentSolitaireCanBeSimulated) {
            this.setState(prevState => ({...prevState,
            solitaireStates: StateHelper.changeSimulation(prevState.solitaireStates, solitaireType, solitaireState, simulations)}));
        }
    }

    getVerifications() : Verifications | null {
        let solitaireState = this.tryGetStateWithStatisticsAndVerifications(this.state.currentSolitaire);
        if (!this.state.currentSolitaireCanBeVerified || solitaireState === undefined) {
            return null;
        }
        return solitaireState.verifications;
    }

    getSolitaireView(solitaireType: SolitaireType) : Newable<IBaseSolitaireView> | undefined {
        for (var connector of viewConnectors) {
            if (!connector.isDefined() || connector.solitaire.name !== solitaireType.name) continue;
            return connector.view;
        }
        return undefined;
    }

    getStateOfCurrentSolitaire() : IBaseSolitaireState {
        let currentSolitaire = this.state.currentSolitaire;
        let state = this.state.solitaireStates.get(currentSolitaire);
        if (state === undefined) {
            throw new Error("State does not contain solitaire state of " + currentSolitaire.name);
        }
        return state;
    }

    extraStatisticsView() : ReactNode {
        let solitaireView = this.state.currentSolitaireView;
        let state = this.tryGetStateWithStatistics(this.state.currentSolitaire);
        if (solitaireView !== undefined && state !== undefined) {
            let view = new solitaireView();
            if (view.isConnected() && view.hasExtraStatisticsView()) {
                return view.getExtraStatisticsView(state.statistics).view();
            }
        }
        return <div/>;
    }

    verifyView() : ReactNode {        
        let solitaireType = this.state.currentSolitaire;
        let solitaireState = this.tryGetStateWithStatisticsAndVerifications(solitaireType);
        let solitaire = new solitaireType();
        if (!this.state.currentSolitaireCanBeVerified || !solitaire.canBeVerified() || solitaireState === undefined) return <div/>;  
        return <VerificationView
            solitaireType={solitaireType}
            solitaire={solitaire}
            solitaireState={solitaireState}
            enableFunctionality={this.state.isAuthorized}
            steps={this.steps}
            runWrapper={this.runWrapper}
            collectResults={this.collectResults}
            numberOfGames={this.state.numberOfGames}
            changeVerifications={this.changeVerifications}
        />

    }
    
    simulationView() : ReactNode {
        let solitaireType = this.state.currentSolitaire;
        let solitaireState = this.tryGetStateWithStatisticsAndSimulations(solitaireType);
        if (!this.state.currentSolitaireCanBeSimulated || solitaireState === undefined) return <div/>;
        return <SimulationView
            solitaireType={solitaireType}
            solitaireState={solitaireState}
            steps={this.steps}
            enableFunctionality={true}
            runWrapper={this.runWrapper}
            changeSimulation={this.changeSimulation}
        />
    }

    baseStatistics(): ReactNode {
        let solitaireType = this.state.currentSolitaire;
        let solitaireState = this.tryGetStateWithStatistics(solitaireType);
        if (solitaireState === undefined) return <div/>
        return <StatisticsView
            solitaireType={solitaireType}
            solitaireState={solitaireState}
        />
    }

    render() {
        return <Alert variant="secondary" className="text-center" style={{'marginBottom': 0}}>
            <Card className="text-center">
                <Card.Body>
                    <Card.Title><h4>Solitaire Simulator</h4></Card.Title>
                    <Form.Group className="form-group">
                        Current solitaire:
                        <select onChange={this._onCurrentSolitaireChange} 
                            value={this.state.currentSolitaire.name}
                            //disabled={disableEditing}
                            >
                                {activeSolitaires.map((subvalue, subindex) => {
                                    return <option key={"S_" + subvalue.name} value={subvalue.name}>{new subvalue().name}</option>
                                })}
                        </select>
                    </Form.Group>
                    <Form.Group>
                        <Button variant="primary"
                            className={"function-button mr-1 " + (this.state.simulationIsRunning ? 'error-button' : '')}
                            //disabled={!this.state.isAuthorized}
                            onClick={this.runThrough}>
                            Run
                        </Button>
                        {this.state.isAuthorized ? 
                        <Button variant="primary"
                            className={"function-button mr-1"}
                            onClick={evt => this.refreshStatisticsForCurrentSolitaire()}>
                            Refresh statistics
                        </Button>
                        :
                        ''}
                        <Button variant="outline-primary" onClick={this.reset}>Reset current session</Button>
                    </Form.Group>
                    {this.baseStatistics()}
                </Card.Body>
            </Card>
            <Tabs 
                               id="controlled-tab-example"
                               defaultActiveKey={UiWindow.ExtraStatistics}
                className="mt-3"
                >
            <Tab eventKey={UiWindow.ExtraStatistics} title="Extra statistics">
                {this.extraStatisticsView()}
            </Tab>
            {this.state.isAuthorized ? 
            <Tab eventKey={UiWindow.VerifyResults} title="Verify" disabled={!this.state.currentSolitaireCanBeVerified}>
                {this.verifyView()}
            </Tab>
            : ''
            }
            <Tab eventKey={UiWindow.GraphicSimulation} title="Graphic runthrough" disabled={!this.state.currentSolitaireCanBeSimulated}>
                {this.simulationView()}
            </Tab>
            </Tabs>
            <Card className="text-center">
                <Card.Body>
                    <Row>
                        <Col sm />
                        <Col sm className="text-left">
                            <Card.Title><h5>Settings</h5></Card.Title>
                        </Col>
                    </Row>
                    <Container>
                        <Row>
                            <Col sm />
                            <Col sm className="text-left">
                                <label>
                                <input type="checkbox" name="logsEnabled"
                                    disabled={this.state.simulationIsRunning}
                                    checked={this.state.logsEnabled}
                                    onChange={this._onLogsEnabledChange} />
                                    &nbsp;Enable live logs in console
                                </label>
                            </Col>
                        </Row>
                        {this.state.isAuthorized ?
                        <Row>
                            <Col sm />
                            <Col sm className="text-left">
                                <label>
                                <input type="checkbox" name="saveResultInDatabase"
                                    checked={this.state.currentSolitaireCanBeSaved && this.state.saveResultsInDatabase}
                                    disabled={!this.state.currentSolitaireCanBeSaved || !this.state.isAuthorized}
                                    onChange={this._onSaveResultsInDatabaseChange} />
                                    &nbsp;Save results
                                </label>
                            </Col>
                        </Row> : ''}
                        <Row>
                            <Col sm />
                            <Col sm className="text-left form-group">
                                <label>
                                    Number of games to run:&nbsp;
                                </label>
                                <input className="form-control" type="text" name="numberOfGames"
                                    value={this.state.numberOfGames}
                                    disabled={this.state.simulationIsRunning}
                                    onChange={evt => this._onNumberOfGamesChange(evt)} />
                            </Col>
                        </Row>
                    </Container>
                    <LoginView onLoginSuccess={this.onLoginSuccess}/>
                </Card.Body>
            </Card>
        </Alert>
    }
}


// ========================================

ReactDOM.render(
    React.createElement(Game, null),
    //React.createElement(TestComponent, null),
    document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();