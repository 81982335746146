import { BaseSolitaireLayout, SolitaireLayoutInternal } from "../baseSolitaireLayout";
import { ISolitaireLayout } from "../solitaireLayout";
import { Card } from "../../card";
import { IdiotSolutionSet } from "./idiotSolutionSet";
import { ICloneable } from "../../helper";
import { IdiotSolitaire } from "./idiotSolitaire";

export class IdiotLayout extends SolitaireLayoutInternal<IdiotSolitaire, IdiotSolutionSet>
implements ISolitaireLayout<IdiotSolitaire>, ICloneable<IdiotLayout>, BaseIdiotLayout {
    clone(): IdiotLayout {
        return this.cloneFunction<IdiotLayout>(IdiotLayout);
    }
    discardedOfSuit: number[][] = [];
    largestNonDiscardedNonAceCardsOfSuit: (number | null)[] = [null, null, null, null];
    constructor(init?: Partial<IdiotLayout>) {
        super();
        return this.constructorFunction(IdiotSolutionSet, init);
    }

    isWon(): boolean {
        if (this.stockPile === null || this.stockPile.length > 0)
        {
            return false;
        }
        for (var i = 0; i < 4; i++)
        {
            if (this.columns[i].length !== 1 || !Card.isAce(this.columns[i][0])) return false;
        }
        return true;
    }

    preserialize(): object[] {
        if (this.stockPile === null) {
            throw new Error("Stock pile is non-null in every initial layout of The Idiot")
        }
        return [ this.stockPile ];
    }
}

export class BaseIdiotLayout extends BaseSolitaireLayout<IdiotSolitaire> {}
