import { ISolutionSet } from "../solutionSet";
import { Card } from "../../card";
import { IdiotSolitaire } from "./idiotSolitaire";

export class IdiotSolutionSet implements ISolutionSet<IdiotSolitaire> {
    columns!: number[][];
    stockPile!: number[][];
    
    constructor(init?: Partial<IdiotSolutionSet>) {
        return Object.assign(this, init);
    }

    translateToString(): string {
        let str = "";
        let numberOfRoundsLeft = this.stockPile.length;
        str += String.fromCharCode(47 + numberOfRoundsLeft) + "|";
        if (numberOfRoundsLeft === 0)
        {
            var nonEmptyColumnsSorted = [];
            for (var m = 0; m < this.columns.length; m++) {
                if(this.columns[m].length > 0) nonEmptyColumnsSorted.push(this.columns[m]);
            }
            nonEmptyColumnsSorted.sort((a, b) => a[0] - b[0]);
            let amount = nonEmptyColumnsSorted.length;
            for (var n = 0; n < amount; n++)
            {
                let column = nonEmptyColumnsSorted[n];
                for (var j = 0; j < column.length; j++)
                {
                    str += Card.convertCardToChar(column[j]);
                }
                if (n !== this.columns.length - 1) str += "|";
            }
        }
        else
        {
            for (var i = 0; i < this.columns.length; i++)
            {
                let col = this.columns[i];
                for (var k = 0; k < col.length; k++)
                {
                    str += Card.convertCardToChar(col[k]);
                }
                if (i !== this.columns.length - 1) str += "|";
            }
        }
        return str;
    }

    translateToStringArray() : string[]
    {
        let str = [];
        let numberOfRoundsLeft = this.stockPile.length;
        str.push(String.fromCharCode(47 + numberOfRoundsLeft));
        if (numberOfRoundsLeft === 0)
        {
            var nonEmptyColumnsSorted = [];
            for (var m = 0; m < this.columns.length; m++) {
                if(this.columns[m].length > 0) nonEmptyColumnsSorted.push(this.columns[m]);
            }
            nonEmptyColumnsSorted.sort((a, b) => a[0] - b[0]);
            let amount = nonEmptyColumnsSorted.length;
            for (var n = 0; n < amount; n++)
            {
                let column = nonEmptyColumnsSorted[n];
                let word = "";
                for (var j = 0; j < column.length; j++)
                {
                    word += Card.convertCardToChar(column[j]);
                }
                str.push(word);
            }
        }
        else
        {
            for (var i = 0; i < this.columns.length; i++)
            {
                let col = this.columns[i];
                let word = "";
                for (var k = 0; k < col.length; k++)
                {
                    word += Card.convertCardToChar(col[k]);
                }
                str.push(word);
            }
        }
        return str;
    }
}