import { ISolitaire } from "../Models/solitaire";
import { Newable } from "../newable";
import { ISolitaireView } from "./solitaireView";

export class ViewConnector<TSolitaire extends ISolitaire, TSolitaireView extends ISolitaireView<TSolitaire>> implements BaseViewConnector {
    view: Newable<TSolitaireView>;
    solitaire: Newable<TSolitaire>;
    constructor(solitaire: Newable<TSolitaire>, view: Newable<TSolitaireView>) {
        this.solitaire = solitaire;
        this.view = view;
    }

    isDefined<TSolitaire extends ISolitaire, TSolitaireView extends ISolitaireView<TSolitaire>>() : this is ViewConnector<TSolitaire, TSolitaireView> {
        return 'solitaire' in this && 'view' in this;
    }
}

export interface BaseViewConnector {
    isDefined: <TSolitaire extends ISolitaire, TSolitaireView extends ISolitaireView<TSolitaire>>()
        => this is ViewConnector<TSolitaire, TSolitaireView>;
}