import React, { ReactNode } from "react"
import { Col, Row, Card } from 'react-bootstrap';
import { Outcome } from "../Models/outcome";
import CSS from 'csstype';
import { StatisticsClass } from "../Models/statistics";
import { ScorpionSolitaire } from "../Models/Scorpion/scorpionSolitaire";
import { IExtraStatisticsView } from "./extraStatisticsView";

export class ScorpionExtraStatisticsView implements IExtraStatisticsView {
    statistics: StatisticsClass<ScorpionSolitaire>;

    constructor(statistics: StatisticsClass<ScorpionSolitaire>) {
        this.statistics = statistics;
    }

    statisticForLength(subindex: number, subvalue: Outcome) : ReactNode {
        if (Object.keys(this.statistics.games).length === 0) return "0";
        let won : ReactNode | null = null,
            lost : ReactNode | null = null,
            undetermined : ReactNode | null = null;
        let hasWonGames = this.statistics.games[Outcome.Won][subvalue] > 0;
        let hasLostGames = this.statistics.games[Outcome.Lost][subvalue] > 0;
        let hasUndeterminedGames = this.statistics.games[Outcome.Undetermined][subvalue] > 0;
        let wonStyle : CSS.Properties = { fontWeight: 'bold', color: 'goldenrod' };
        let lostStyle : CSS.Properties = { fontWeight: 'bold', color: '#ff6e6b' };
        let undeterminedStyle : CSS.Properties = { fontWeight: 'bold' };
        //let onlyOneCategory = [hasWonGames, hasLostGames, hasUndeterminedGames].filter(x => x).length === 1;
        if (hasWonGames) {
            won = <span key={"H" + subindex} style={wonStyle}>{this.statistics.games[Outcome.Won][subvalue]}</span>;
        }
        if (hasLostGames) {
            lost = <span key={"J" + subindex} style={lostStyle}>{this.statistics.games[Outcome.Lost][subvalue]}</span>;
        }
        if (hasUndeterminedGames) {
            undetermined = <span key={"K" + subindex} style={undeterminedStyle}>{this.statistics.games[Outcome.Undetermined][subvalue]}</span>;
        }
        let result = [won, lost, undetermined].filter(x => x !== null);
        return result.length > 0 ? <div>{result.reduce((prev, curr) => [prev, ' / ', curr])}</div> : 0;
    }
    
    view() : ReactNode {
        let viewColumns = [];
        for (let i = 0; i < 4; i++) {
            let array = [];
            for (let j = 1; j <= 14; j++) {
                let k = j + i * 14;
                if (k > 0 && k < 57) { array.push(k); }
            }
            viewColumns.push(array);
        }

        return <Card className="text-center">

        <Card.Body>
            <Row>
                <Col sm>
                    <h6>Number of games with specific top distance from initial tableau:</h6>
                </Col>
            </Row>
            <Row>
                <Col sm={2}/>
                {viewColumns.map((value, index) => {
                    return <div className="col-sm-2" key={index}>
                        {value.map((subvalue, subindex) => {
                            return <Row key={"G" + subindex}>
                                <Col sm>{subvalue}</Col>
                                <Col sm className="text-info">
                                    {this.statisticForLength(subindex, subvalue)}
                                </Col>
                            </Row>
                        })}
                    </div>
                })}
                <Col sm={2}/>
            </Row>
        </Card.Body>
    </Card>
    }
}