import { Outcome } from "./outcome";
import { ICloneable } from "../helper";
import { ISolitaire } from "./solitaire";

export class StatisticsClass<TSolitaire extends ISolitaire> implements ICloneable<StatisticsClass<TSolitaire>>
{
    public constructor (init?: Partial<StatisticsClass<TSolitaire>>) {
        if (init == null) { this.initialize();}
        Object.assign(this, init);
    }
    clone(): StatisticsClass<TSolitaire> {
        return new StatisticsClass(JSON.parse(JSON.stringify(this)));
    }
    initialize() {
        this.won = 0;
        this.lost = 0;
        this.unwinnable = 0;
        this.undetermined = 0;
        this.percentageString = "0%";
    }
    won!: number;
    lost!: number;
    unwinnable!: number;
    undetermined!: number;
    games: EnumDictionary<Outcome, { [key: number]: number }> = StatisticsClass.getNewGames();
    percentageString: string | undefined;

    static getNewGames() : EnumDictionary<Outcome, { [key: number]: number }> {
        return {[Outcome.Won]: {}, [Outcome.Lost]: {}, [Outcome.Undetermined]: {}, [Outcome.Unwinnable]: {} };
    }
}

export type EnumDictionary<T extends string | symbol | number, U> = {
    [K in T]: U;
};