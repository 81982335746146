export class Card {
    static getSuit(card: number): number {
        // Only works for positive numbers.
        return ((card - 1) / 13) >> 0;
    }

    static convertCardToChar(card: number) : string {
        return String.fromCharCode(card + (card < 27 ? 64 : 70));
    }

    static getRank(card: number) : number {
        return 13 - (card - 1) % 13;
    }

    static hasValue(card: number, value: number) : boolean {
        return (card + value - 1) % 13 === 0;
    }

    static isKing(card: number) : boolean {
        return this.hasValue(card, 13);
    }

    static isAce(card: number) : boolean {
        return this.hasValue(card, 1);
    }

    static CardWithSameSuitAndOneValueLowerFor(card: number) : number | null
    {
        return this.isAce(card) ? null : card + 1;
    }

    static doFisherYatesShuffle<T>(array: T[]) : T[] {
        // The Fisher-Yates shuffle, see https://bost.ocks.org/mike/shuffle/
        var m = array.length, t, i;

        while (m > 0) {
            i = Math.floor(Math.random() * m--); // Choose random number between 0 and m, subtract m by 1
            t = array[m]; // Save the current value of array[m]
            // Swap the values of array[m] and array[i]. Now array[m] has been chosen randomly and we can proceed to array[m-1] in the next iteration
            array[m] = array[i];
            array[i] = t;
        }
        return array;
    }
}