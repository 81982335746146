import { MoveMethods } from "../moveMethods";
import { ScorpionLayout } from "./scorpionLayout";
import { ScorpionSolutionSet } from "./scorpionSolutionSet";
import { ScorpionResultBody } from "./scorpionResultBody";
import { ScorpionMoveConsequence } from "./scorpionMoveConsequence";
import { SolitaireMove } from "../solitaireMove";
import { MoveObject } from "../moveObject";
import { ScorpionLayoutOverview } from "./scorpionLayoutOverview";
import { LeftoverMove, MoveToColumn } from "./scorpionMoves";
import { Card } from "../../card";
import { FailureHistory } from "../failureHistory";
import { ScorpionRunLog } from "./scorpionRunLog";
import { ScorpionSolitaire } from "./scorpionSolitaire";

export class ScorpionMoveMethods extends MoveMethods<ScorpionSolitaire, ScorpionMoveConsequence, ScorpionLayout, ScorpionSolutionSet, ScorpionResultBody, ScorpionRunLog> {
    
    performMove(layout: ScorpionLayout, theMoveToDo: SolitaireMove<ScorpionSolitaire>): ScorpionMoveConsequence {
        // TODO: Previously, newColumns, newLeftovers and newFaceDownCards were defined via .slice(0) on properties of the step.columnsAndLeftovers object, but that altered the original history[0] object. Why?
        if (!layout || !JSON.stringify(layout)) debugger;
        let cal = layout.clone();
        let wasTheMoveExternal = theMoveToDo instanceof LeftoverMove;
        let newColumns = cal.columns, newLeftovers = cal.leftovers, newFaceDownCards = cal.faceDownCards, solutionSet = cal.solutionSet;
        let leftoverColumns = ScorpionSolitaire.leftoverColumns;
        if (!leftoverColumns) debugger;

        if (!wasTheMoveExternal) {
            var asMoveToColumn = theMoveToDo as MoveToColumn;
            let fromColumn = asMoveToColumn.fromColumn;

            // Trivial moves do not alter the solution set
            if (!asMoveToColumn.isTrivialKingMove) solutionSet = this.addInternalMoveCardToSolutionSet(solutionSet, newColumns[fromColumn][asMoveToColumn.fromColumnPosition]);

            for (let i = asMoveToColumn.fromColumnPosition; i < asMoveToColumn.lengthOfFromColumn; i++) {
                newColumns[asMoveToColumn.toColumn].push(newColumns[fromColumn][i]);
            }
            for (let j = asMoveToColumn.fromColumnPosition; j < asMoveToColumn.lengthOfFromColumn; j++) {
                newColumns[fromColumn].pop();
            }
            if (fromColumn < newFaceDownCards.length && asMoveToColumn.fromColumnPosition === newFaceDownCards[fromColumn].length) {
                newFaceDownCards[fromColumn].pop();
            }

            // Construct winnability helper from scratch if we haven't yet played the leftover cards (it is irrelevant afterwards)
            if (newLeftovers.length > 0) {
                for (let y = 0; y < leftoverColumns.length; y++) {
                    let lc = leftoverColumns[y];
                    solutionSet.winnabilityHelper[lc] = newColumns[lc].length === 0 ? 0 : (Card.isKing(newColumns[lc][0]) ? newColumns[lc][0] : "-");
                }
            }
        } else {
            for (let i = 0; i < newLeftovers.length; i++) {
                newColumns[leftoverColumns[i]].push(newLeftovers[i]);
            }
            wasTheMoveExternal = true;
            solutionSet = this.addExternalMoveToSolutionSet(solutionSet, newColumns, leftoverColumns);
            newLeftovers = [];
        }
        var step = new ScorpionLayout();
        step.columns = newColumns;
        step.leftovers = newLeftovers;
        step.faceDownCards = newFaceDownCards;
        step.solutionSet = solutionSet;
        if (!(solutionSet instanceof ScorpionSolutionSet)) {
            debugger;
        }
        return new ScorpionMoveConsequence(step, wasTheMoveExternal);
    }

    decideWhichMoveToDo(step: ScorpionLayoutOverview) {
        let stepsUntried = []; // Find the moves not yet made
        for (let i = 0; i < step.possibleMoves.length; i++) {
            if (step.movesTriedOut.indexOf(i) === -1) stepsUntried.push(i);
        };

        // Check if it is possible to uncover a face-down card first
        for (let i = 0; i < stepsUntried.length; i++) {
            let index = stepsUntried[i];
            let move = step.possibleMoves[index];
            if (move instanceof LeftoverMove) continue;
            let asMoveToColumn = move as MoveToColumn;
            let fromColumn = asMoveToColumn.fromColumn;
            if (fromColumn < step.columnsAndLeftovers.faceDownCards.length) {
                let faceDownColumn = step.columnsAndLeftovers.faceDownCards[fromColumn];
                if (faceDownColumn.length > 0 && asMoveToColumn.fromColumnPosition === faceDownColumn.length) return new MoveObject(index, move);
            }
        }

        // Next check if there is a non-king move    
        for (let i = 0; i < stepsUntried.length; i++) {
            let index = stepsUntried[i];
            let move = step.possibleMoves[index];
            if (move instanceof LeftoverMove) continue;
            let toColumnLength = (move as MoveToColumn).lengthOfToColumn;
            if (toColumnLength > 0) return new MoveObject(index, move);
        }

        // If there are only king moves or external moves, let's at least ensure that the non-trivial ones get picked first
        for (let i = 0; i < stepsUntried.length; i++) {
            let index = stepsUntried[i];
            let move = step.possibleMoves[index];
            if (move instanceof LeftoverMove) return new MoveObject(index, move);
        }
        for (let i = 0; i < stepsUntried.length; i++) {
            let index = stepsUntried[i];
            let move = step.possibleMoves[index] as MoveToColumn;
            if (!move.isTrivialKingMove) return new MoveObject(index, move);
        }

        // Finally, use first available move (it will be trivial at this point)
        return new MoveObject(stepsUntried[0], step.possibleMoves[stepsUntried[0]]);
    }

    

    addExternalMoveToSolutionSet(oldSolutionSet: ScorpionSolutionSet, newColumns: number[][], leftoverColumns: number[]) : ScorpionSolutionSet {
        let newSolved = oldSolutionSet.solved.slice(0);
        let solutionSetLeftovers: { [key: number]: number; } = {};

        for (let i = 0; i < leftoverColumns.length; i++) {
            let value = -1;
            let columnIndex = leftoverColumns[i];
            let col = newColumns[columnIndex];
            let l = col.length - 1;

            if (newSolved.indexOf(col[l]) > -1) debugger;
            if ((l === 0 && Card.isKing(col[0])) || (l > 0 && !Card.isKing(col[l]) && col[l - 1] + 1 === col[l])) { newSolved = this.doStandardMove(newSolved, col[l]); } // If the remainder card is at the right place, the solution set should reflect that
            if (l === 0 && !Card.isKing(col[0])) value = 0;
            if (l > 0 && (col[l - 1] + 1 !== col[l] || Card.isKing(col[l]))) value = col[l - 1];
            solutionSetLeftovers[col[l]] = value;
        }

        return new ScorpionSolutionSet({solved: newSolved, leftovers: solutionSetLeftovers, winnabilityHelper: {}});
    }

    getResultBody(resultBody: ScorpionResultBody, failureHistory: FailureHistory) : ScorpionRunLog {
        var log = new ScorpionRunLog(resultBody.forLog, {history: failureHistory});
        return log;
    }

    addInternalMoveCardToSolutionSet(oldSolutionSet: ScorpionSolutionSet, cardMoved: number) : ScorpionSolutionSet {
        let newSolved = this.doStandardMove(oldSolutionSet.solved, cardMoved);
        let newLeftovers = oldSolutionSet.leftovers;
        if (Object.keys(oldSolutionSet.leftovers).indexOf(cardMoved + "") > -1) {
            newLeftovers = JSON.parse(JSON.stringify(newLeftovers));
            newLeftovers[cardMoved] = -1;
        }
        // The winnability helper is added in the performMove method
        var result = new ScorpionSolutionSet({solved: newSolved, leftovers: newLeftovers, winnabilityHelper: {}});
        return result;
    }

    doStandardMove(solved: number[], cardMoved: number) : number[] {
        let newSolved = solved.slice(0);
        let l = solved.length;
        if (l === 0) { newSolved = [cardMoved]; }
        else {
            let start = 0, end = solved[0] > cardMoved ? 0 : l;
            while (start < end) {
                let pivot = start + Math.floor((end - start) / 2);
                if (cardMoved < solved[pivot]) {
                    end = pivot;
                } else {
                    start = pivot;
                }
                if (end === start || end - start === 1) break;
            }
            newSolved.splice(end, 0, cardMoved);
            if (newSolved[1] < newSolved[0]) debugger;
        }

        if (cardMoved === 1 && newSolved.indexOf(cardMoved) === -1) debugger;
        return newSolved;
    }

    
    calculateMoveToColumn(result: SolitaireMove<ScorpionSolitaire>[], i: number, columnsAndLeftovers: ScorpionLayout, theCardToLookFor: number) : void {
        let cal = columnsAndLeftovers;
        for (let j = 0; j < cal.columns.length; j++) {
            if (j !== i && (j > 3 || cal.faceDownCards[j].indexOf(theCardToLookFor) === -1)) { // j !== i because we never move a part of a column to somewhere within itself...!
                let index = cal.columns[j].indexOf(theCardToLookFor);
                // We will allow trivial king moves - the failure checker will ensure that we do not just move kings around all over the place over and over. To accommodate this we add a check to the result object.
                if (index > -1) {
                    let moveToColumnObject = new MoveToColumn(j, index, i, cal.columns[j].length, cal.columns[i].length, index === 0 && Card.isKing(theCardToLookFor));
                    // Only consider trivial moves if the leftover cards have not yet been played
                    if (!moveToColumnObject.isTrivialKingMove || cal.leftovers.length > 0) result.push(moveToColumnObject);
                }
            }
        }
    }

    investigatePossibleMoves(cal: ScorpionLayout): SolitaireMove<ScorpionSolitaire>[] {
        let result : SolitaireMove<ScorpionSolitaire>[] = [];

        for (let i = cal.columns.length - 1; i > -1; i--) {
            let currentColumn = cal.columns[i];
            if (currentColumn.length === 0) {
                // If the current column has no cards, check for kings
                for (let k = 0; k < 4; k++) {
                    let theCardToLookFor = k * 13 + 1;
                    this.calculateMoveToColumn(result, i, cal, theCardToLookFor);
                }
            } else {
                let upperMostCardInCurrentColumn = currentColumn[currentColumn.length - 1];
                // Check if upperMostCard is an ace
                if (Card.isAce(upperMostCardInCurrentColumn)) continue;
                let theCardToLookFor = upperMostCardInCurrentColumn + 1;
                this.calculateMoveToColumn(result, i, cal, theCardToLookFor);
            }
        }
        if (cal.leftovers.length > 0) {
            result.push(new LeftoverMove());
        };
        return result;
    }

}