import { ICloneable } from "../../helper";

export class ScorpionBlock implements ICloneable<ScorpionBlock> {
    column: number[];
    bottom: number[];
    top: number[];
    upsideDown: number[];
    space: number[] = [];
    faceDown: number[] = [];
    foundBlock: boolean;
    foundProperBlock: boolean;

    constructor(column: number[], bottom: number[], top: number[], upsideDown: number[]){
        this.column = column;
        this.bottom = bottom;
        this.top = top;
        this.upsideDown = upsideDown;
        this.foundBlock = false;
        this.foundProperBlock = false;
    }
    clone(): ScorpionBlock {
        return JSON.parse(JSON.stringify(this)) as ScorpionBlock;
    }
}

export class ScorpionBlockLog {
    column: number[];
    bottom: number[];
    top: number[];
    upsideDown: number[] | null;
    space: number[];
    faceDown: number[] | null;
    foundBlock: boolean;
    foundProperBlock: boolean;

    constructor(block: ScorpionBlock){
        this.column = block.column;
        this.bottom = block.bottom;
        this.top = block.top;
        this.upsideDown = block.upsideDown;
        this.foundBlock = block.foundBlock;
        this.foundProperBlock = block.foundProperBlock;
        this.space = block.space;
        this.faceDown = block.faceDown;
    }
}