import { activeSolitaires } from "./activeSolitaires";
import { ISolitaireLayoutOutput } from "./Models/baseSolitaireLayout";
import { Dictionary } from "./Models/dictionary";
import { ISolitaire } from "./Models/solitaire";
import { IBaseSolitaireState, ISolitaireState, ISolitaireStateWithSimulations, ISolitaireStateWithStatistics, ISolitaireStateWithVerifications, Simulations, Verifications } from "./Models/solitaireState";
import { SolitaireType, SolitaireTypeOf } from "./Models/solitaireType";
import { State } from "./Models/state";
import { StatisticsClass } from "./Models/statistics";
import { Newable } from "./newable";

    
export function getCurrentSolitaireByString(currentSolitaire: string) : Newable<ISolitaire> { 
    if (!currentSolitaire) {
        throw new Error("Current solitaire is not specified!");
    }
    let solitaires = activeSolitaires.filter(sol => sol.name === currentSolitaire);
    if (solitaires.length === 0) {            
        throw new Error("No solitaire matches current solitaire!");
    }
    return solitaires[0];
}

export function getCurrentSolitaire(state: Readonly<State>) : SolitaireType { 
    return getCurrentSolitaireByString(state.currentSolitaire.name);
}

export function currentSolitaireIs<TSolitaire extends ISolitaire>(state: Readonly<State>, solitaire: Newable<TSolitaire>) : boolean {
    return getCurrentSolitaire(state).name === solitaire.name;
}

export function prepareSolitaireStates(state: Readonly<State>) : Dictionary<SolitaireType, IBaseSolitaireState> {
    var states = new Dictionary<SolitaireType, IBaseSolitaireState>();
    for (var i = 0; i < activeSolitaires.length; i++) {
        var solitaireType = activeSolitaires[i];
        var solitaire = new solitaireType();
        if (solitaire.canBePlayed()) {
            states.set(solitaireType, solitaire.prepareSolitaireState(state));
        }
    }
    return states;
    // var states = activeSolitaires.filter(solitaireType => new solitaireType().canBePrepared())
    // .map(solitaireType => ({[solitaireType.name]: new solitaireType().canBePrepared() ? new solitaireType().prepareSolitaireState(state)}));
    // return Object.assign({}, ...states);
}



export function changeVerifications<TSolitaire extends ISolitaire, TSolitaireState extends ISolitaireStateWithVerifications<TSolitaire>>
    (prevSolitaireStates: Dictionary<SolitaireType, IBaseSolitaireState>,
        solitaireType: SolitaireTypeOf<TSolitaire>,
        solitaireState: TSolitaireState,
        verifications: Verifications) : Dictionary<SolitaireType, IBaseSolitaireState> {
        let newSolitaireState = {...solitaireState, verifications};
    return updateSolitaireStatesWithNewValue(prevSolitaireStates, solitaireType, newSolitaireState);
}


export function changeSimulation<TSolitaire extends ISolitaire, TSolitaireState extends ISolitaireStateWithSimulations<TSolitaire, TLayout>, TLayout extends ISolitaireLayoutOutput<TSolitaire>>
    (prevSolitaireStates: Dictionary<SolitaireType, IBaseSolitaireState>,
        solitaireType: SolitaireTypeOf<TSolitaire>,
        solitaireState: TSolitaireState,
        simulations: Simulations) : Dictionary<SolitaireType, IBaseSolitaireState> {
    let newSolitaireState = {...solitaireState, simulations};
    return updateSolitaireStatesWithNewValue(prevSolitaireStates, solitaireType, newSolitaireState);
}

export function updateSolitaireStates<TSolitaire extends ISolitaire, TSolitaireState extends ISolitaireStateWithStatistics<TSolitaire>>
    (prevSolitaireStates: Dictionary<SolitaireType, IBaseSolitaireState>,        
        solitaireType: SolitaireType,
        solitaireState: TSolitaireState,
        statistics: StatisticsClass<TSolitaire>,
        total: number, 
        topNumberOfStepsRequired: number | undefined) : Dictionary<SolitaireType, IBaseSolitaireState> {
        let newSolitaireState = {...solitaireState, statistics, topNumberOfStepsRequired, total};
        return updateSolitaireStatesWithNewValue(prevSolitaireStates, solitaireType, newSolitaireState);
}

export function updateSolitaireStatesWithNewValue<TSolitaire extends ISolitaire, TSolitaireState extends ISolitaireState<TSolitaire>>
    (prevSolitaireStates: Dictionary<SolitaireType, IBaseSolitaireState>,
        solitaireType: SolitaireTypeOf<TSolitaire>,
        newState: TSolitaireState) : Dictionary<SolitaireType, IBaseSolitaireState> {
    let newSolitaireStateContainer = new Dictionary<SolitaireType, IBaseSolitaireState>();
    let oldSolitaireState = prevSolitaireStates.get(solitaireType);
    newSolitaireStateContainer.set(solitaireType, Object.assign({}, oldSolitaireState, newState));
    return new Map([...prevSolitaireStates, ...newSolitaireStateContainer]);
}