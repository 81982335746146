import { ScorpionSolitaire } from "../Models/Scorpion/scorpionSolitaire";
import { StatisticsClass } from "../Models/statistics";
import { IHasExtraStatisticsView } from "./extraStatisticsView";
import { ScorpionExtraStatisticsView } from "./scorpionExtraStatisticsView";
import { SolitaireView } from "./solitaireView";

export class ScorpionSolitaireView extends SolitaireView<ScorpionSolitaire> 
    implements IHasExtraStatisticsView<ScorpionExtraStatisticsView, ScorpionSolitaire> {
    getExtraStatisticsView(statistics: StatisticsClass<ScorpionSolitaire>) : ScorpionExtraStatisticsView {
            return new ScorpionExtraStatisticsView(statistics);
    }
}