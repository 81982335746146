import { ICloneable } from "../helper";
import { Newable } from "../newable";
import { ISolitaire } from "./solitaire";
import { IBaseSolitaireState } from "./solitaireState";
import { Dictionary } from "./dictionary";
import { IBaseSolitaireView } from "../Views/solitaireView";
import { UiWindow } from "../Components/uiWindow";

export class PartialState implements ICloneable<PartialState>
{
    logsEnabled: boolean = true;
    numberOfGames!: number;
    saveResultsInDatabase: boolean = true;
    isAuthorized: boolean = false;
    selectedTab: UiWindow = UiWindow.ExtraStatistics;
    
    currentSolitaire!: Newable<ISolitaire>;
    currentSolitaireView: Newable<IBaseSolitaireView> | undefined;
    currentSolitaireCanBeSaved: boolean = false;
    currentSolitaireCanBeVerified: boolean = false;
    currentSolitaireCanBeSimulated: boolean = false;
    solitaireStates!: Dictionary<Newable<ISolitaire>, IBaseSolitaireState>;
    simulationIsRunning: boolean = false;
    simulationIndex: number = 0;
    public constructor(init?: Partial<PartialState>) {
        return Object.assign(this, init);
    }
    clone(): PartialState {
        return new PartialState(JSON.parse(JSON.stringify(this)));
    }
}

export class State extends PartialState
{
    accessToken: string | undefined;
    public constructor(init?: Partial<State>) {
        super();
        return Object.assign(this, init);
    }
}